import React, { useState } from 'react';
import { WWCharacter } from '../common/components/ww-character';
import { WWCharactersDMG } from '../data/ww-char-damage';
import { Alert, Row, Col, Accordion } from 'react-bootstrap';
import { WWWeaponEntry } from '../weapons/ww-weapon';
import { WWSet } from '../common/components/ww-set';
import { WWStat } from '../common/components/ww-stat';
import { WWEchoEntry } from '../echoes/ww-echo';
import { YoutubeEmbed } from '../../common/components/youtube-embed';

interface IProps {
  slug: string;
  name: string;
  element: string;
}

export const WWCharacterCalcs: React.FC<IProps> = ({ slug, name, element }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dpsRanking, setDpsRanking] = useState(
    WWCharactersDMG.find((rankingCharacter) => {
      if (rankingCharacter.slug === slug) return true;
    })
  );

  return (
    <>
      {!dpsRanking ? (
        <>
          <div className="info-box">
            <p>
              <strong className={`${element}`}>{name}</strong>{' '}
              <strong>calculations aren't available yet.</strong>
            </p>
          </div>
        </>
      ) : (
        <>
          <>
            <Alert variant="primary alert-red">
              <p>
                <strong>
                  Don't use the damage output calculations as an indication on
                  who is worth pulling or investing
                </strong>{' '}
                as those are just simulations and in day-to-day gameplay there's
                a lot variables and also the skill of the player is important -
                if you can't consistently perform the character's rotation,
                their damage will suffer.
              </p>
              <p>
                As usual,{' '}
                <strong>don't compare the numbers between characters</strong> -
                especially between Main DPS and Hybrids as they fulfill
                different roles in the team.
              </p>
            </Alert>
            <h5>Additional information</h5>
            <p>
              The numbers below show the character's{' '}
              <strong>
                DPS (Damage Per Second) and DMG (total damage done)
              </strong>
              . When selecting the rotations we prioritized achieving 100
              Concerto as fast as possible and once that condition was
              fulfilled, we focused on maximizing the damage output. As or if
              new and more optimal rotations are found, we'll update the
              characters calculations as needed. Listed character calculations
              are done without buffs or any damage contribution from teammates
              (full solo). They only include what the character, their Weapon,
              Echo and gear are capable of during their ideal Burst or Concerto
              rotation when starting with their Intro and Resonance Liberation
              available.
            </p>
            <p>
              <strong>
                Do note that DPS and DMG aren’t perfect metrics for a
                character’s individual value and should be taken with a grain of
                salt.
              </strong>
              Anything pertaining a character’s relevance in the meta is
              addressed in our Review and Tier List, which the numbers below
              shouldn’t be used for as an end-all-be-all to. Instead, DPS and
              DMG are good metrics for a character’s sequence value and
              comparison between an individual character’s different rotations.
              We recommend using those metrics for such purposes.
            </p>
            {slug === 'cantarella' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Sequence 3: Cantarella's Sequence 3 allows her Ultimate to
                    initiate Mirage, allowing you to skip using Delusive Dive if
                    you so desire. Trimming Delusive Dive from the rotation can
                    result in an increase in DPS beyond what is displayed below.
                  </li>
                </ul>
              </>
            )}
            {slug === 'phoebe' && (
              <>
                <p>
                  <strong>Important!</strong> Each of Phoebe's roles have
                  different rotations and playstyles but currently only her DPS
                  focused style (Absolution) is used. For that reason all
                  calculations and examples will be focused on that.
                </p>
                <ul>
                  <li>
                    Sequence 4: When playing Phoebe with Spectro Rover S6, or
                    other characters with sources of Spectro RES Shred, this
                    sequence will perform notably worse than represented due to
                    dilution.
                  </li>
                </ul>
              </>
            )}
            {slug === 'lumi' && (
              <>
                <p>
                  <strong>Important!</strong> Lumi Sequences have a few unique
                  properties which make them more or less useful in certain
                  scenarios. Here is what you need to be aware of:
                </p>
                <ul>
                  <li>
                    Sequence 5's benefit is dependant on the rotation you're
                    using on Lumi and how many Sparks are left for her to
                    consume with her outro - we haven't assigned a damage
                    increase to it for this reason.
                  </li>
                  <li>
                    Sequence 6 is both a buff for Lumi's personal damage and a
                    powerful supportive tool. When playing Lumi as a hybrid
                    supporter you'll want to move the Ultimate toward the end of
                    the rotation to maximise the sequence buff uptime on the
                    rest of the team. When playing Lumi as the main damage
                    dealer you'll want the opposite - use it close to the start
                    of the fight to ensure Lumi has the buff throughout her full
                    rotation. Currently Lumi is expected to be played more in
                    her Hybrid role and that's how we've calculated this
                    sequence. When playing Lumi as a DPS you can exxpect a large
                    gain from this sequence.
                  </li>
                </ul>
              </>
            )}
            {slug === 'the-shorekeeper' && (
              <>
                <p>
                  <strong>Important!</strong> Sequence calculations have been
                  configured to not only ensure that Shorekeeper always has 250%
                  Energy Regeneration when using her Resonance Liberation, but
                  also maximize the damage. At Sequence 3, she gains additional
                  Concerto Energy shortening her rotation - this Sequence impact
                  is dependent on the Rectifier she uses. With an S1 Variation
                  or her Signature, you can remove some Basic Attacks from her
                  standard rotation and omit them altogether with higher ranks
                  of these weapons.
                </p>
                <p>
                  With this in mind, she can drastically reduce her field time
                  requirements resulting in very quick execution of her Intro,
                  Resonance Skill, Resonance Liberation and finally her Outro,
                  often resulting in incredibly high damage. However, keep in
                  mind that these damage numbers can’t be sustained and are a
                  result of very low field time combined with high burst damage
                  potential due to her empowered Intro.
                </p>
              </>
            )}
            {slug === 'zhezhi' && (
              <>
                <p>
                  <strong>Important!</strong> In the Sequence calculations below
                  we have assumed you'll be fightning enemies for at least 2 or
                  more Zhezhi rotations and will be able to maintain and refresh
                  her buffs that last for 27 - 30 seconds at a time without
                  letting them expire. For shorter fights or if you're not
                  confident keeping buffs active you can consider Sequence 1, 3
                  and 4 to be less valuable than is displayed as they'll have
                  lower uptime for you than we assume.
                </p>
              </>
            )}
            {slug === 'yinlin' && (
              <>
                <p>
                  <strong>Important!</strong> Due to the incredibly short nature
                  of Yinlin's rotation when implementing Swap-Cancels, her DPS
                  numbers are incredibly high. If you are unable to perform
                  Swap-Cancels her DPS will be lower,{' '}
                  <strong className="red">
                    Please also pay close attention to S2 as it makes her
                    already short rotation even shorter - this is the reason it
                    results in such a tremendous damage gains but smaller total
                    damage numbers.
                  </strong>
                </p>
              </>
            )}
            {slug === 'jinhsi' && (
              <>
                <p>
                  <strong>Important!</strong> Jinhsi’s Sequences have a few
                  unique properties which make them more or less useful in
                  certain scenarios. Here is what you need to be aware of:
                </p>
                <ul>
                  <li>
                    Jinhsi’s Sequence 2 grants her a full Forte Gauge at the
                    start of combat where as she would normally start at 0.
                    Unfortunately in ToA you cannot make use of this Sequence
                    more than once, meaning outside of the very first rotation
                    it loses its value. The result is that this Sequence gains
                    or loses power depending on how long a fight is, if an enemy
                    dies on your very first rotation - it’s an excellent
                    Sequence offering a tremendous boost but if it takes 7 or 8
                    rotations this Sequences loses a colossal amount of its
                    value. For this reason we haven’t considered it in our
                    calculations and instead have chosen to post this
                    disclaimer.
                  </li>
                  <li>
                    Jinhsi’s S3 has similar properties to her S2 but in reverse.
                    It grants a 25% Attack boost which stacks twice with you
                    gaining 1 stack per rotation. The longer the fight, the more
                    valuable this Sequence becomes but in shorter battles it
                    loses value. Unlike Jinhsi’s S2 though, the ramp-up time is
                    swift only requiring 2 rotations, and it only really loses
                    power if you’re against enemies that die within 1 or 2
                    rotations meaning in Tower of Adversary it will be at close
                    to full power for the majority of combat. For this reason
                    we’ve included this Sequence in calculations assuming you’ll
                    be up against enemies that will last for more than a couple
                    rotations.
                  </li>
                  <li>
                    Jinhsi's rotation damage figures have been calculated
                    assuming you'll gain an average of 40 Incandecent Forte
                    Gauge stacks per rotation. Higher stack numbers will
                    increase total damage and lower stack sizes will reduce it.
                  </li>
                </ul>
              </>
            )}
            <Row xs={1} xl={1} className="dps-comparison">
              <Col>
                <div className="box">
                  <h5>{dpsRanking.name} in 1 Target scenario</h5>
                  <p className="summary">
                    Rotation time:{' '}
                    <strong className={element}>{dpsRanking.time}s</strong>{' '}
                    {slug === 'jianxin' && (
                      <>
                        (Post S1: <strong className={element}>11.29s</strong>;
                        Post S6: <strong className={element}>8.02s</strong>)
                      </>
                    )}
                    {slug === 'roccia' && (
                      <>
                        (Post S1: <strong className={element}>6.45s</strong>;
                        Post S6: <strong className={element}>7.5s</strong>)
                      </>
                    )}
                    {slug === 'yinlin' && (
                      <>
                        (Post S2: <strong className={element}>2.83s</strong>)
                      </>
                    )}
                    {slug === 'the-shorekeeper' && (
                      <>
                        (Post S3: <strong className={element}>1.26s</strong>)
                      </>
                    )}
                    {slug === 'camellya' && (
                      <>
                        (Post S6: <strong className={element}>22.62s</strong>)
                      </>
                    )}
                  </p>
                  <div className={`simulation-container ${slug}`}>
                    <div className="simulation-row">
                      <div className="character">
                        <WWCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                      </div>
                      <div className="chart">
                        <div className="info-2">
                          <p>{dpsRanking.name} (S6)</p>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar top-1`}
                            style={{ width: '100%' }}
                          ></div>
                          <div className="data">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e6.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e6.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e6 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row">
                      <div className="character">
                        <WWCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                      </div>
                      <div className="chart">
                        <div className="info-2">
                          <p>{dpsRanking.name} (S5)</p>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar top-2`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e5 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e5.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e5.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e5 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row">
                      <div className="character">
                        <WWCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                      </div>
                      <div className="chart">
                        <div className="info-2">
                          <p>{dpsRanking.name} (S4)</p>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar top-3`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e4 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e4.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e4.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e4 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row">
                      <div className="character">
                        <WWCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                      </div>
                      <div className="chart">
                        <div className="info-2">
                          <p>{dpsRanking.name} (S3)</p>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar top-4`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e3 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data">
                            <span className="dps dark">
                              {dpsRanking.dmg.damage_e3.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e3.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e3 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row">
                      <div className="character">
                        <WWCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                      </div>
                      <div className="chart">
                        <div className="info-2">
                          <p>{dpsRanking.name} (S2)</p>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar top-5`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e2 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data">
                            <span className="dps dark">
                              {dpsRanking.dmg.damage_e2.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e2.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e2 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row">
                      <div className="character">
                        <WWCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                      </div>
                      <div className="chart">
                        <div className="info-2">
                          <p>{dpsRanking.name} (S1)</p>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar top-6`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e1 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data">
                            <span className="dps dark">
                              {dpsRanking.dmg.damage_e1.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e1.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e1 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row">
                      <div className="character">
                        <WWCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                      </div>
                      <div className="chart">
                        <div className="info-2">
                          <p>{dpsRanking.name} (S0)</p>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar top-7`}
                            style={{
                              width:
                                (dpsRanking.dps.damage /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data">
                            <span className="dps dark">
                              {dpsRanking.dmg.damage.toLocaleString()} DMG (
                              {dpsRanking.dps.damage.toLocaleString()} DPS)
                            </span>
                            <span className="percent">100.00%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {slug === 'calcharo' && (
              <>
                <h5>Additional information</h5>
                <p>
                  Here's a comparison between the damage output of 3 Death
                  Messenger rotation (our baseline) and 2 and 4 (the dream
                  that's hard to achieve).
                </p>
                <Row xs={1} xl={1} className="dps-comparison">
                  <Col>
                    <div className="box">
                      <h5>4DM vs 3DM vs 2DM</h5>
                      <div className={`simulation-container ${slug}`}>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>{dpsRanking.name} (4DM)</p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-1`}
                                style={{ width: '100%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">449,369 DMG</span>
                                <span className="percent">114.77%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>{dpsRanking.name} (3DM)</p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-2`}
                                style={{ width: '87.13%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">391,539 DMG</span>
                                <span className="percent">100%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>{dpsRanking.name} (2DM)</p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-3`}
                                style={{ width: '68%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">305,568 DMG</span>
                                <span className="percent">78.04%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {slug === 'jiyan' && (
              <>
                <h5>Additional information</h5>
                <p>
                  Here is the damage difference between the two different
                  rotations that can be used on Jiyan - the basic rotation
                  that's easy to use and the harder, Double Dragon rotation.
                  While the raw DMG output of both is similar, Double Dragon
                  rotation takes less field time (12.17s vs 10.94s) which
                  results in higher DPS.
                </p>
                <Row xs={1} xl={1} className="dps-comparison">
                  <Col>
                    <div className="box">
                      <h5>Basic vs Double Dragon</h5>
                      <div className={`simulation-container ${slug}`}>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>{dpsRanking.name} (Double Dragon)</p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-1`}
                                style={{ width: '100%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">
                                  362,739 DMG (33,775 DPS)
                                </span>
                                <span className="percent">112.33%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>{dpsRanking.name} (Basic)</p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-3`}
                                style={{ width: '89.02%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">
                                  360,023 DMG (30,067 DPS)
                                </span>
                                <span className="percent">100%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {slug === 'yinlin' && (
              <>
                <h5>Additional information</h5>
                <p>
                  Here's a comparison between Swap-Cancelling different parts of
                  Yinlin rotation. Compared to Calcharo, Swap-Cancelling her is
                  much easier and that's why the 'Full Cancel' variant is used
                  by us as the default one as everyone can learn how to do it
                  properly with some practice.
                </p>
                <Row xs={1} xl={1} className="dps-comparison">
                  <Col>
                    <div className="box">
                      <h5>Swap Meter</h5>
                      <div className={`simulation-container ${slug}`}>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>
                                {dpsRanking.name} (ECHO + SKILL + CHAMELEON +
                                B4)
                              </p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-1`}
                                style={{ width: '100%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">42,078 DPS</span>
                                <span className="percent">100%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>
                                {dpsRanking.name} (ECHO + SKILL + CHAMELEON)
                              </p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-2`}
                                style={{ width: '84.19%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">35,424 DPS</span>
                                <span className="percent">84.19%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>{dpsRanking.name} (ECHO + SKILL)</p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-3`}
                                style={{ width: '63.2%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">26,594 DPS</span>
                                <span className="percent">63.2%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>{dpsRanking.name} (ECHO ONLY)</p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-4`}
                                style={{ width: '50.39%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">21,201 DPS</span>
                                <span className="percent">50.39%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {slug === 'changli' && (
              <>
                <h5>Additional information</h5>
                <p>
                  Changli has multiple points within a usual combat rotation in
                  which she can execute Swap-Cancels. At a minimum we recommend
                  Swap-Cancelling her final Forte Heavy Attack: Flaming
                  Sacrifice, used after her Ultimate and her Inferno Rider Echo.
                  Outside of this Changli can optionally choose to Swap-Cancel
                  all of her True Sight Conquest casts to better protect herself
                  in battle and gain tremendous damage. Below you can observe
                  the damage increases and decreases associated with doing so.
                  As a baseline we expect most players to execute at least 2
                  Conquest Swap-Cancels.
                </p>
                <Row xs={1} xl={1} className="dps-comparison">
                  <Col>
                    <div className="box">
                      <h5>Swap Meter</h5>
                      <div className={`simulation-container ${slug}`}>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>
                                {dpsRanking.name} (3 Conquest + Post Ult Heavy +
                                Echo)
                              </p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-1`}
                                style={{ width: '100%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">41,795 DPS</span>
                                <span className="percent">112.39%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>
                                {dpsRanking.name} (2 Conquest + Post Ult Heavy +
                                Echo [Base])
                              </p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-2`}
                                style={{ width: '88.98%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">36,811 DPS</span>
                                <span className="percent">100.00%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>
                                {dpsRanking.name} (1 Conquest + Post Ult Heavy +
                                Echo)
                              </p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-3`}
                                style={{ width: '80.14%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">32,889 DPS</span>
                                <span className="percent">90.07%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simulation-row">
                          <div className="character">
                            <WWCharacter
                              slug={dpsRanking.slug}
                              mode="icon"
                              enablePopover
                            />
                          </div>
                          <div className="chart">
                            <div className="info-2">
                              <p>
                                {dpsRanking.name} (Post Ult Heavy + Echo
                                [Minimum])
                              </p>
                            </div>
                            <div className="dps-bar-outside">
                              <div
                                className={`dps-bar top-4`}
                                style={{ width: '72.91%' }}
                              ></div>
                              <div className="data">
                                <span className="dps">29,723 DPS</span>
                                <span className="percent">81.94%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <h5>Details about the calculations</h5>
            {dpsRanking.disclaimer != '' && (
              <p>
                <strong>Disclaimer:</strong> {dpsRanking.disclaimer}
              </p>
            )}
            <Accordion
              defaultActiveKey="0"
              className="default-look dps-details"
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Rotation{' '}
                  <strong className={element}>
                    &nbsp;[{dpsRanking.time}s]&nbsp;
                  </strong>
                  {slug === 'jianxin' && (
                    <>
                      - post S1:{' '}
                      <strong className={element}>&nbsp;[11.29s]</strong>; post
                      S6: <strong className={element}>&nbsp;[8.02s]</strong>
                    </>
                  )}
                  {slug === 'roccia' && (
                    <>
                      - post S1:{' '}
                      <strong className={element}>&nbsp;[6.45s]</strong>; post
                      S6: <strong className={element}>&nbsp;[7.5s]</strong>
                    </>
                  )}
                  {slug === 'yinlin' && (
                    <>
                      - post S2:{' '}
                      <strong className={element}>&nbsp;[2.83s]</strong>
                    </>
                  )}
                  {slug === 'the-shorekeeper' && (
                    <>
                      - post S3:{' '}
                      <strong className={element}>&nbsp;[1.26s]</strong>
                    </>
                  )}
                  {slug === 'camellya' && (
                    <>
                      - post S6:{' '}
                      <strong className={element}>&nbsp;[22.62s]</strong>
                    </>
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Here's the{' '}
                    <strong>rotation we have used to calculate the DPS</strong>{' '}
                    (Damage Per Second) shown above.
                  </p>
                  {slug === 'rover-aero' && (
                    <>
                      <blockquote>
                        <h6>Standard Rotation</h6>

                        <ul>
                          <li>Intro</li>
                          <li>Forte: Cloudburst Dance P1</li>
                          <li>Forte: Cloudburst Dance P2</li>
                          <li>Ultimate</li>
                          <li>Skill: Awakening Gale</li>
                          <li>Forte: Cloudburst Dance P1</li>
                          <li>Forte: Cloudburst Dance P2</li>
                          <li>Skill: Skyfall Severance (OptionaL)</li>
                          <li>Mid-Air Atk (Plunge)</li>
                          <li>
                            Forte: Unbound Flow P1 (Optionally Swap Cancel &
                            Immediately return)
                          </li>
                          <li>Echo</li>
                          <li>Forte: Unbound Flow P2</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'cantarella' && (
                    <>
                      <blockquote>
                        <h6>Standard Rotation</h6>
                        <p>
                          Echo Usage: Most of Cantarella's main Echo choices
                          will want to be used at the end of her rotation and
                          swap cancelled, but some can be used at other times
                          situationally.
                        </p>
                        <ul>
                          <li>Intro Skill: Ripple</li>
                          <li>Basic P3</li>
                          <li>Skill: Graceful Step</li>
                          <li>Ultimate: Flowing Suffocation</li>
                          <li>Heavy ATK: Delusive Dive</li>
                          <li>Skill: Flickering Reverie</li>
                          <li>Forte: Phantom String P1</li>
                          <li>Forte: Phantom String P2</li>
                          <li>Forte: Phantom String P3</li>
                          <li>Forte: Skill: Perception Drain</li>
                          <li>Echo</li>
                          <li>Outro Skill</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'brant' && (
                    <>
                      <blockquote>
                        <h6>Standard Rotation</h6>
                        <ul>
                          <li>Intro</li>
                          <li>
                            Skill: Plunging Attack (OPTIONAL) (Immediately
                            Cancel With Ultimate)
                          </li>
                          <li>Ultimate</li>
                          <li>Mid-Air Atk P2</li>
                          <li>Mid-Air Atk P2: Charged Attack</li>
                          <li>Mid-Air Atk P2: Flip</li>
                          <li>Mid-Air Atk P3</li>
                          <li>Mid-Air Atk P3: Flip</li>
                          <li>Forte: Returned from Ashes</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'phoebe' && (
                    <>
                      <blockquote>
                        <h6>Standard Rotation</h6>
                        <ul>
                          <li>Intro Skill</li>
                          <li>Skill: Mirror Summon</li>
                          <li>Skill: Mirror Teleport (Optional)</li>
                          <li>Forte: Absolution Litany</li>
                          <li>Ultimate: Dawn of Englightenment</li>
                          <li>Skill: Basic: Chamuel's Star P1</li>
                          <li>Skill: Basic: Chamuel's Star P2</li>
                          <li>Skill: Basic: Chamuel's Star P3</li>
                          <li>Forte: Heavy Atk: Starflash</li>
                          <li>Skill: Basic: Chamuel's Star P1</li>
                          <li>Skill: Basic: Chamuel's Star P2</li>
                          <li>Skill: Basic: Chamuel's Star P3</li>
                          <li>Forte: Heavy Atk: Starflash</li>
                          <li>Skill: Basic: Chamuel's Star P1</li>
                          <li>Skill: Basic: Chamuel's Star P2</li>
                          <li>Skill: Basic: Chamuel's Star P3</li>
                          <li>Forte: Heavy Atk: Starflash</li>
                          <li>Skill: Basic: Chamuel's Star P1</li>
                          <li>Skill: Basic: Chamuel's Star P2</li>
                          <li>Skill: Basic: Chamuel's Star P3</li>
                          <li>Forte: Heavy Atk: Starflash</li>
                          <li>Outro Skill</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'roccia' && (
                    <>
                      <blockquote>
                        <h6>Standard Rotation</h6>
                        <ul>
                          <li>Intro</li>
                          <li>
                            Basic P4 (Cancel animation after 100 Fantasy is
                            gained using skill)
                          </li>
                          <li>Skill: Acrobatic Trick</li>
                          <li>Forte: Basic: Real Fantasy P1</li>
                          <li>Forte: Basic: Real Fantasy P2</li>
                          <li>
                            Forte: Basic: Real Fantasy P3 (Cancel animation
                            after the bounce lands with Ultimate)
                          </li>
                          <li>Ultimate: Commedia Improvviso!</li>
                          <li>Echo (Swap Cancel)</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6>S1+ Shortened Rotation</h6>
                        <ul>
                          <li>Intro</li>
                          <li>Skill: Acrobatic Trick</li>
                          <li>Forte: Basic: Real Fantasy P1</li>
                          <li>Forte: Basic: Real Fantasy P2</li>
                          <li>
                            Forte: Basic: Real Fantasy P3 (Cancel animation
                            after the bounce lands with Ultimate)
                          </li>
                          <li>Ultimate: Commedia Improvviso!</li>
                          <li>Echo (Swap Cancel)</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'jianxin' && (
                    <>
                      <p>Coming soon!</p>
                    </>
                  )}
                  {slug === 'carlotta' && (
                    <>
                      <blockquote>
                        <h6>Burst Rotation</h6>
                        <ul>
                          <li>Start with 30 Substance</li>
                          <li>
                            Intro: Wintertime Aria (Generate 3 “Moldable
                            Crystals”, Generate 30 “Substance”)
                          </li>
                          <li>
                            Skill: Art of Violence (Generate “Moldable
                            Crystals”)
                          </li>
                          <li>
                            Skill: Chromatic Splendor - (Consume 6 “Moldable
                            Crystals” for 60 “Substance”)(Enter “Final Bow”)
                          </li>
                          <li>
                            Mid-Air Atk (To return to the ground to continue
                            combo)
                          </li>
                          <li>
                            Forte: Heavy Attack: Imminent Oblivion (Consume 120
                            “Substance” to reduce Cooldown of Skill)
                          </li>
                          <li>Ultimate: Era of New Wave</li>
                          <li>Ultimate: Death Knell</li>
                          <li>Ultimate: Death Knell</li>
                          <li>Ultimate: Death Knell</li>
                          <li>Ultimate: Death Knell</li>
                          <li>Ultimate: Fatal Finale</li>
                          <li>
                            Skill: Art of Violence (Generate 3 “Moldable
                            Crystals”)
                          </li>
                          <li>
                            Skill: Chromatic Splendor - (Consume 3 “Moldable
                            Crystals”for 30 “Substance”)
                          </li>
                          <li>Echo (Swap Cancel)</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'lumi' && (
                    <>
                      <blockquote>
                        <h6>Rotation</h6>
                        <ul>
                          <li>Intro</li>
                          <li>Ultimate</li>
                          <li>
                            Skill: Energized Pounce (Optionally Swap Cancel and
                            immediately back)
                          </li>
                          <li>Red Spotlight: Basic Attack 1</li>
                          <li>Red Spotlight: Basic Attack 2</li>
                          <li>
                            Red Spotlight: Basic Attack 3 (Optionally Swap
                            Cancel and immediately back)
                          </li>
                          <li>Skill: Energized Rebound</li>
                          <li>Yellow Light: Basic Attack</li>
                          <li>Channelled Dash: Glare x 6</li>
                          <li>Skill: Energized Pounce</li>
                          <li>Echo (Swap Cancel)</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'camellya' && (
                    <>
                      <blockquote>
                        <h6>Rotation</h6>
                        <ul>
                          <li>Intro</li>
                          <li>Skill: Crimson Blossom</li>
                          <li>Ultimate</li>
                          <li>Skill: Basic Attack: Vining Waltz 1</li>
                          <li>Skill: Basic Attack: Vining Waltz 2</li>
                          <li>Skill: Basic Attack: Vining Waltz 3</li>
                          <li>Skill: Blazing Waltz (Full Channel)</li>
                          <li>Skill: Basic Attack: Vining Waltz 4</li>
                          <li>Forte: Skill: Ephemeral</li>
                          <li>Skill: Basic Attack: Vining Waltz 1</li>
                          <li>Skill: Basic Attack: Vining Waltz 2</li>
                          <li>Skill: Basic Attack: Vining Waltz 3</li>
                          <li>Skill: Blazing Waltz (Full Channel)</li>
                          <li>Skill: Basic Attack: Vining Waltz 4</li>
                          <li>Skill: Floral Ravage</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'the-shorekeeper' && (
                    <>
                      <blockquote>
                        <h6>Standard Rotation</h6>
                        <ul>
                          <li>Intro Skill: Discernment</li>
                          <li>Basic P1</li>
                          <li>Basic P2</li>
                          <li>Basic P3</li>
                          <li>Basic P4</li>
                          <li>Forte: Heavy Atk: Illation</li>
                          <li>Skill: Chaos Theory</li>
                          <li>Echo</li>
                          <li>Liberation</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'xiangli-yao' && (
                    <>
                      <blockquote>
                        <h6>Combo</h6>
                        <ul>
                          <li>
                            Echo (Use before combo but refer to Echo
                            recommendations for exact specifics for your chosen
                            Echo)
                          </li>
                          <li>Intro</li>
                          <li>Ultimate: Cogiation Model</li>
                          <li>Ultimate: Skill: Divergence</li>
                          <li>Forte: Mid- Air Attack: Revamp</li>
                          <li>
                            Forte: Skill: Law of Reigns (Optionally Swap-Cancel)
                          </li>
                          <li>Ultimate: Basic: Pivot - Impale P1</li>
                          <li>Ultimate: Basic: Pivot - Impale P2</li>
                          <li>Ultimate: Basic: Pivot - Impale P3</li>
                          <li>
                            Forte: Skill: Law of Reigns (Optionally Swap-Cancel)
                          </li>
                          <li>Ultimate: Skill: Divergence</li>
                          <li>Forte: Mid- Air Atk: Revamp </li>
                          <li>Forte: Skill: Law of Reigns (Swap-Cancel)</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'zhezhi' && (
                    <>
                      <blockquote>
                        <h6>Rotation</h6>
                        <ul>
                          <li>Intro Skill</li>
                          <li>Basic P1</li>
                          <li>Basic P2</li>
                          <li>Basic P3</li>
                          <li>
                            Resonance Skill: Manifestation (Tap to Execute
                            Ground combo, Hold To Execute Air Combo)
                          </li>
                          <li>Forte: Heavy Atk: Conjuration</li>
                          <li>
                            Forte: Resonance Skill: Stroke of Genius (Optionally
                            Jump Cancel)
                          </li>
                          <li>
                            Forte: Resonance Skill: Stroke of Genius (Optionally
                            Jump Cancel)
                          </li>
                          <li>Forte: Resonance Skill: Creations Zenith</li>
                          <li>
                            Ultimate: Living Canvas (Optionally Cancel Forte
                            Skill)
                          </li>
                          <li>
                            Echo: Impermenance Heron (Swap Cancel immediately
                            after activation)
                          </li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'changli' && (
                    <>
                      <blockquote>
                        <h6>Rotation</h6>
                        <p>
                          Below is a sample of how Changli's kit can play out in
                          battle, keep in mind as a quick swap cooldown based
                          character you should follow the ability priority above
                          first and foremost but can refer to below to get an
                          idea of what a full 100 concerto rotation looks like
                          for her.
                        </p>
                        <ul>
                          <li>
                            Inferno Rider Echo (Swap After 3rd Slash Begins)
                          </li>
                          <li>Intro</li>
                          <li>Basic Attack: True Sight: Charge</li>
                          <li>Mid-Air Heavy Attack</li>
                          <li>Basic P3</li>
                          <li>Basic P4</li>
                          <li>
                            Basic Attack: True Sight: Conquest (Optionally
                            Swap-Cancel)
                          </li>
                          <li>Skill: True Sight: Capture </li>
                          <li>
                            Basic Attack: True Sight: Conquest (Optionally
                            Swap-Cancel)
                          </li>
                          <li>Skill: True Sight: Capture </li>
                          <li>
                            Basic Attack: True Sight: Conquest (Optionally
                            Swap-Cancel)
                          </li>
                          <li>Forte: Heavy Attack: Flaming Sacrifice</li>
                          <li>Ultimate</li>
                          <li>
                            Forte: Heavy Atk: Flaming Sacrifice (Swap-Cancel)
                          </li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'jinhsi' && (
                    <>
                      <p>
                        <strong>COMBO REQUIREMENTS</strong>
                      </p>
                      <p>
                        Unlike most other characters Jinhsi is not reliant on
                        Ultimate or Forte Gauge burst windows - she is instead
                        cooldown-based and as such only needs to work around
                        those, which can be easily fit into a followable
                        rotation. One thing Jinhsi does need in order to execute
                        her rotation though, is to perform her Intro each and
                        every time before she starts dealing her damage. Because
                        of this requirement it's imperative you build up
                        Concerto on another character before beginning her
                        rotation (or else it wont work).
                      </p>
                      <blockquote>
                        <h6>Rotation</h6>
                        <ul>
                          <li>Intro</li>
                          <li>
                            Echo (Use immediately if Jue or before combo and
                            swap cancel if Aix)
                          </li>
                          <li>Ultimate</li>
                          <li>Skill: Overflowing Radiance</li>
                          <li>Incarnation: Skill: Crescent Divinity</li>
                          <li>Incarnation: Basic P1</li>
                          <li>Incarnation: Basic P2</li>
                          <li>Incarnation: Basic P3</li>
                          <li>Incarnation: Basic P4</li>
                          <li>Skill: Illuminous Ephiphany</li>
                          <li>
                            Outro (Swap to another character to build concerto
                            and wait for cooldowns)
                          </li>
                          <li>Intro</li>
                          <li>Skill: Overflowing Radiance</li>
                          <li>Incarnation: Skill: Crescent Divinity</li>
                          <li>Incarnation: Basic P1</li>
                          <li>Incarnation: Basic P2</li>
                          <li>Incarnation: Basic P3</li>
                          <li>Incarnation: Basic P4</li>
                          <li>Skill: Illuminous Ephiphany Star DMG</li>
                          <li>Skill: Illuminous Ephiphany Sun DMG</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'rover-havoc' && (
                    <>
                      <blockquote>
                        <h6>LONG BURST COMBO</h6>
                        <p>
                          Havoc Rover's long burst combo deals higher total
                          damage at the cost of longer duration required to
                          execute it, It is primarily suitable for team's where
                          they are the sole DPS accompanied by two Supportive
                          characters. As long as warm-up is executed, you can
                          move straight into Umbra state following your Intro
                          and standard skill. After that, aim to execute 2 full
                          Basic Attack sequences followed by an Umbra skill to
                          extend the Umbra state. Then comes using your Ultimate
                          skill followed by Dreamless Echo into Swap-Cancel in
                          the remaining Umbra state time.
                        </p>
                        <ul>
                          <li>Intro</li>
                          <li>Skill</li>
                          <li>Heavy ATK: Devastation</li>
                          <li>Umbra: Basic P1</li>
                          <li>Umbra: Basic P2</li>
                          <li>Umbra: Basic P3</li>
                          <li>Umbra: Basic P4</li>
                          <li>Umbra: Basic P5</li>
                          <li>Umbra: Basic P1</li>
                          <li>Umbra: Basic P2</li>
                          <li>Umbra: Basic P3</li>
                          <li>Umbra: Basic P4</li>
                          <li>Umbra: Basic P5</li>
                          <li>
                            Skill: Umbra: Lifetaker (Optionally Swap-Cancel)
                          </li>
                          <li>Ultimate</li>
                          <li>Echo (Dreamless Swap-Cancel)</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6>SHORT BURST COMBO</h6>
                        <p>
                          The Short Burst Combo deals majority of its damage in
                          short duration burst combo, and thus is much more
                          suitable for Quick Swap teams, containing another DPS
                          in a team that desires field time for their own
                          combo's execution. This combo completely eliminates
                          all the Basic Attack sequences and instead focuses on
                          utilizing abilities with highest damage, able to be
                          executed in shortest time with least amount of
                          vulnerabilities.
                        </p>
                        <ul>
                          <li>Intro</li>
                          <li>Skill</li>
                          <li>Heavy Attack: Devastation</li>
                          <li>
                            Skill: Umbra: Lifetaker (Optionally Swap-Cancel)
                          </li>
                          <li>Ultimate</li>
                          <li>Echo (Dreamless Swap-Cancel)</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'jiyan' && (
                    <>
                      <blockquote>
                        <h6>BURST COMBO</h6>
                        <p>
                          Jiyan’s enhanced Basic Attack, Lance of Qinglong only
                          deals damage, when Jiyan is executing the animation
                          associated, afterwards despite animation continuing on
                          its own, it deals no damage. As such, Dash-Canceling
                          Lance or interrupting it with another skill, allows
                          you to exit the animation state, securing any damage
                          done so far while forgoing any further damage. His
                          Ultimate duration allows for either 6 Lance’s + Skill
                          or 7 Lance’s before being left with little duration,
                          to maximize gain from this duration, we start and
                          cancel another Lance of Qinglong, gaining a bit of
                          damage at cost of minimum rotation time. It can be
                          seen as executing 6 Lance’s, a skill and small portion
                          of 7th Lance of Qinglong with this trick, however it
                          can be ignored if desired, for it is minimal damage
                          gain.
                        </p>
                        <ul>
                          <li>
                            Echo (Use Jiyan's Echo right before entering the
                            burst combo so the Echo buff is active. Refer to the
                            Echo guide on whether you'll need to Swap- Cancel
                            it.)
                          </li>
                          <li>Intro</li>
                          <li>Forte: Emerald Storm</li>
                          <li>
                            Heavy Attack: Lance of Qinqlong P1 (Interrupt as
                            fast as possible using Resonance Skill)
                          </li>
                          <li>Resonance Skill</li>
                          <li>Heavy Attack: Lance of Qinqlong P1</li>
                          <li>Heavy Attack: Lance of Qinqlong P2</li>
                          <li>Heavy Attack: Lance of Qinqlong P3</li>
                          <li>Heavy Attack: Lance of Qinqlong P1</li>
                          <li>Heavy Attack: Lance of Qinqlong P2</li>
                          <li>Heavy Attack: Lance of Qinqlong P3</li>
                          <li>Resonance Skill</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'danjin' && (
                    <>
                      <blockquote>
                        <h6>BURST COMBO</h6>
                        <p>
                          Danjin’s burst combo doesn’t require it to be executed
                          uninterrupted - allowing her to use Dodges or Swapping
                          to other characters before returning to finish the
                          combo. This is as her preferred Echo (Dreamless) has
                          no buff associated with it and her kit lacks any
                          strict duration to fit damage into. However, if using
                          a team with specific Deepen amplification effects that
                          prohibit switching, consider using that first before
                          performing Swap mid combo.
                        </p>
                        <ul>
                          <li>
                            Echo (Use before combo but refer to Echo
                            recommendations for exact specifics for your chosen
                            Echo)
                          </li>
                          <li>Intro</li>
                          <li>Skill: Crimson Erosion I</li>
                          <li>Skill: Crimson Erosion II</li>
                          <li>Ultimate</li>
                          <li>Skill: Carmine Gleam</li>
                          <li>Basic P2</li>
                          <li>Basic P3</li>
                          <li>Skill: Sanguine Pulse I</li>
                          <li>Skill: Sanguine Pulse II</li>
                          <li>Skill: Sanguine Pulse III</li>
                          <li>Skill: Carmine Gleam</li>
                          <li>Basic P2</li>
                          <li>Basic P3</li>
                          <li>Skill: Sanguine Pulse I</li>
                          <li>Skill: Sanguine Pulse II</li>
                          <li>Skill: Sanguine Pulse III</li>
                          <li>Heavy Attack: Chaoscleave Full</li>
                          <li>Heavy Attack: Scatterbloom Full</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'yuanwu' && (
                    <>
                      <p>
                        Unlike most primary DPS and Hybrid dealers, Yuanwu
                        features no set rotation when played and instead focuses
                        on his Ultimate and Resonance Skill, which make up the
                        majority of his endgame capabilities. While we have
                        assigned him a “rotation” for the purpose of
                        calculations, it is better to refer to the following
                        list as things to watch out for during his gameplay
                        instead:
                      </p>
                      <blockquote>
                        <h6>Ability Priority</h6>
                        <ul>
                          <li>Echo </li>
                          <li>Intro (If Available)</li>
                          <li>Skill: Place Thunderwedge</li>
                          <li>Ultimate (Detonates Thunderwedge)</li>
                          <li>Skill: Forte: Rumbling Spark</li>
                          <li>Skill: Place Thunderwedge</li>
                          <li>Outro (If Available)</li>
                          <li></li>
                          <li>
                            Skill: Place Thunderwedge (As needed to keep active
                            and on top of enemies)
                          </li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'encore' && (
                    <>
                      <blockquote>
                        <h6>EASY & BASIC BURST COMBO</h6>
                        <p>
                          When playing teams that focus on Encore's Ultimate or
                          when you have field time to spare after all other
                          cooldowns have been expended in Quick Swap team's
                          execute the following burst combo on Encore.
                        </p>
                        <p>
                          Ending Encore’s Ultimate early to cast Rupture before
                          duration concludes can be a viable strategy in Quick
                          Swap teams, so as to not waste other DPS cooldowns.
                          Whereas, if Encore is the sole DPS that is buffed by
                          teammates, this is generally advised against.
                        </p>
                        <ul>
                          <li>
                            Echo (Use before combo but refer to Echo
                            recommendations for exact specifics for your chosen
                            Echo)
                          </li>
                          <li>Intro</li>
                          <li>Ultimate</li>
                          <li>Skill: Cosmos: Rampage</li>
                          <li>Basic Attack: Cosmos: Frolicking P1</li>
                          <li>Basic Attack: Cosmos: Frolicking P2</li>
                          <li>Basic Attack: Cosmos: Frolicking P3</li>
                          <li>Basic Attack: Cosmos: Frolicking P4</li>
                          <li>Skill: Cosmos: Rampage</li>
                          <li>Basic Attack: Cosmos: Frolicking P1</li>
                          <li>Basic Attack: Cosmos: Frolicking P2</li>
                          <li>Basic Attack: Cosmos: Frolicking P3</li>
                          <li>Basic Attack: Cosmos: Frolicking P4</li>
                          <li>Skill: Cosmos: Rampage</li>
                          <li>
                            Heavy Attack: Cosmos: Rupture (Swap-Cancel The
                            Moment Encore Begin's Channelling)
                          </li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6>ADVANCED BURST COMBO</h6>
                        <p>
                          For those aiming even more damage when playing Encore
                          and are confident in their mechanical abilities, there
                          are a number of tricks you can employ to increase her
                          damage at cost of difficulty increase in battle. This
                          is achieved via executing more Basic Attacks after
                          each Ultimate, here is what you will need to adjust:
                        </p>
                        <ul>
                          <li>
                            Core of new rotates lies in cancellation of Encore’s
                            4th enhanced Basic Attack animation, allowing you to
                            start your next action much faster. This can be
                            achieved by interrupting it via Skill usage or a
                            Dash, you will need to perform one of these cancels
                            3 times during her Ultimate in this new rotation.
                          </li>
                          <li>
                            The other change is, this rotation gives one
                            enhanced Skill use in favor of 4 more Basic Attacks,
                            this leads to increased damage but leaves you with
                            very less time to execute the entire combo without
                            losing out on final Basic Attack, Skill and Forte
                            Heavy Attack if you mess up.
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Echo (Use before combo but refer to Echo
                            recommendations for exact specifics for your chosen
                            Echo)
                          </li>
                          <li>Intro</li>
                          <li>Ultimate</li>
                          <li>Basic Attack: Cosmos: Frolicking P1</li>
                          <li>Basic Attack: Cosmos: Frolicking P2</li>
                          <li>Basic Attack: Cosmos: Frolicking P3</li>
                          <li>Basic Attack: Cosmos: Frolicking P4</li>
                          <li>
                            Skill: Cosmos: Rampage (Use Skill to animation
                            cancel Frolicking P4)
                          </li>
                          <li>Basic Attack: Cosmos: Frolicking P1</li>
                          <li>Basic Attack: Cosmos: Frolicking P2</li>
                          <li>Basic Attack: Cosmos: Frolicking P3</li>
                          <li>
                            Basic Attack: Cosmos: Frolicking P4 (Dash Cancel
                            this attack)
                          </li>
                          <li>Basic Attack: Cosmos: Frolicking P1</li>
                          <li>Basic Attack: Cosmos: Frolicking P2</li>
                          <li>Basic Attack: Cosmos: Frolicking P3</li>
                          <li>Basic Attack: Cosmos: Frolicking P4</li>
                          <li>
                            Skill: Cosmos: Rampage (Use Skill to animation
                            cancel Frolicking P4)
                          </li>
                          <li>
                            Heavy Attack: Cosmos: Rupture (Swap-Cancel the
                            moment Encore begins channelling)
                          </li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'calcharo' && (
                    <>
                      <blockquote>
                        <h6>OPTIMIZED BURST COMBO</h6>
                        <p>
                          Below given is an optimized combo that implements
                          either a Skill Cancel or Dash Cancel to allow faster
                          accumulation of Killing Intent by Calcharo. The
                          slowest part of Hounds Roar 5 Attack combo, required
                          to generate 5 Killing Intent to cast Death Messenger
                          is its part 4 and 5, by using Skill or Dash after 3rd
                          part, you can reset the combo, gaining last 2 charges
                          with part 1 and 2, thus speeding up your access to
                          Death Messengers. While this combo is challenging to
                          execute in spur of the moment, it offers higher chance
                          to execute 3 Death Messengers and minimize time wasted
                          in long attack animations,
                        </p>
                        <p>
                          When playing Calcharo without Quick Swaps, we
                          recommend using Skill Cancel as it generates more
                          Concerto and Resonance Energy, while dealing more
                          damage and being easier to use. When using
                          Swap-Cancels, Calcharo’s skill will be placed on
                          cooldown after one use, forcing use of Dash Cancel to
                          continue the combo. Both approaches are valid and we
                          suggest you try both to find what fits you better,
                          otherwise refer to the easier combo below.
                        </p>
                        <ul>
                          <li>
                            Echo (Use before combo but refer to Echo
                            recommendations for exact specifics for your chosen
                            Echo)
                          </li>
                          <li>Intro</li>
                          <li>Ultimate</li>
                          <li>
                            Heavy Attack: Death Messenger (Optionally
                            Swap-Cancel if you can - for higher damage and to
                            protect Calcharo)
                          </li>
                          <li>Basic Attack: Hounds Roar P1</li>
                          <li>Basic Attack: Hounds Roar P2</li>
                          <li>Basic Attack: Hounds Roar P3</li>
                          <li>
                            Skill: Extermination Order P1 OR Dash Cancel (Use
                            Skill Right after you gain a charge of the forte)
                          </li>
                          <li>Basic Attack: Hounds Roar P1</li>
                          <li>Basic Attack: Hounds Roar P2</li>
                          <li>
                            Heavy Attack: Death Messenger (swap cancel if you
                            can - for higher damage and to protect Calcharo)
                          </li>
                          <li>Basic Attack: Hounds Roar P1</li>
                          <li>Basic Attack: Hounds Roar P2</li>
                          <li>Basic Attack: Hounds Roar P3</li>
                          <li>
                            Skill: Extermination Order P2 OR Dash Cancel (Use
                            Skill right after you gain a charge of the forte)
                          </li>
                          <li>Basic Attack: Hounds Roar P1</li>
                          <li>Basic Attack: Hounds Roar P2</li>
                          <li>
                            Heavy Attack: Death Messenger (Swap-Cancel if you
                            can - for higher damage and to protect Calcharo)
                          </li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                      <Row className="video">
                        <Col xs={12} lg={6}>
                          <YoutubeEmbed embedId="HWzEV-7nMMI" />
                        </Col>
                      </Row>
                    </>
                  )}
                  {slug === 'chixia' && (
                    <>
                      <blockquote>
                        <h6>BURST COMBO</h6>
                        <p>
                          <strong>
                            Chixia features one of the hardest burst combo
                            sequences to successfully complete in endgame
                            content due to multiple reasons such as:
                          </strong>{' '}
                          Using her Echo, especially if buff related Echo such
                          as Inferno Rider or Mech Abomination before initiating
                          her burst combo. With her Echo buff in place, move
                          away from target to build some distance to make
                          landing combo easier and trigger her Intro.
                        </p>
                        <p>
                          Immediately after Intro skill, use her Resonance Skill
                          to consume Forte Gauge built in Warm-Up phase. Repeat
                          this until you reach the indicator on her Forte Gauge
                          then tap Basic Attack to fire BOOM BOOM. A full
                          execution of this ability takes 4 seconds and while it
                          allows movement, failing to complete the full duration
                          will cause massive damage loss.
                        </p>
                        <p>
                          While channeling Chixia’s Forte Gauge, aim for exactly
                          30 Bullets consumption, as any longer will leave you
                          dry for the next channel. While channeling, she gains
                          a massive attack boost, which is why we use it just
                          before her Ultimate, following which use another full
                          Forte channel into BOOM BOOM and lastly Outro, before
                          repeating the whole sequence.
                        </p>
                        <p>
                          <strong>It's extremely important</strong> to note that
                          while pairing her Forte Skills and Ultimate result in
                          large damage increases, it is more important to land
                          them in first place. As such, you are free to
                          completely ignore the sequence below and instead use
                          each ability as opportunity presents itself, even if
                          it ends up in damage loss.
                        </p>
                        <ul>
                          <li>
                            Echo (Use before combo but refer to Echo
                            recommendations for exact specifics for your chosen
                            Echo)
                          </li>
                          <li>Intro</li>
                          <li>Skill: Forte: Thermobaric Bullets</li>
                          <li>Skill: Forte: BOOM BOOM</li>
                          <li>Ultimate</li>
                          <li>Skill: Forte: Thermobaric Bullets</li>
                          <li>Skill: Forte: BOOM BOOM</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'rover-spectro' && (
                    <>
                      <blockquote>
                        <h6>BURST COMBO</h6>
                        <p>
                          Spectro Rover's main role in the fight is to generate
                          concerto quickly, apply and maintain Spectro Frazzle
                          efficiently and apply their echo related buffs at the
                          right moment. The rotation below takes advantage of
                          Rover's fast skill cooldown and quick swap nature to
                          achieve all of these.
                        </p>
                        <p>
                          Time and use Spectro Rover's Echo right before
                          initiating your teams highest damage combos.
                        </p>
                        <ul>
                          <li>Heavy Atk</li>
                          <li>Resonance</li>
                          <li>Aftertune</li>
                          <li>Forte: Resonanting Spin</li>
                          <li>Forte: Resonanting Whirl</li>
                          <li></li>
                          <li>Swap while waiting for Skill CD</li>
                          <li></li>
                          <li>Intro</li>
                          <li>Ultimate</li>
                          <li>Basic P1</li>
                          <li>Basic P2</li>
                          <li>Forte: Resonanting Spin</li>
                          <li>Forte: Resonanting Whirl</li>
                          <li>Forte: Resonating Echoes P1</li>
                          <li>Forte: Resonating Echoes P2</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'aalto' && (
                    <>
                      <blockquote>
                        <ul>
                          <li>
                            Skill: Mist Avatar (Use during another character's
                            rotation to allow for cooldown)
                          </li>
                          <li>Intro</li>
                          <li>Ultimate</li>
                          <li>
                            Skill: Mist Avatar (Can be used after Basic Attacks
                            if still on Cooldown)
                          </li>
                          <li>Basic P1</li>
                          <li>Basic P2</li>
                          <li>Basic P3</li>
                          <li>Basic P4</li>
                          <li>Basic P5</li>
                          <li>Echo</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'yangyang' && (
                    <blockquote>
                      <ul>
                        <li>Intro</li>
                        <li>Resonance Liberation</li>
                        <li>Resonance Skill</li>
                        <li>Basic Attack P1</li>
                        <li>Basic Attack P2</li>
                        <li>Basic Attack P3</li>
                        <li>Basic Attack P4</li>
                        <li>Mid-Air Attack: Feather Release</li>
                        <li>
                          Heron OR Turtle Echo (Swap-Cancel after first part
                          hits)
                        </li>
                        <li>Outro</li>
                      </ul>
                    </blockquote>
                  )}
                  {slug === 'sanhua' && (
                    <>
                      <blockquote>
                        <h6>HEAVY ATK CONCERTO ROTATION</h6>
                        <ul>
                          <li>Intro</li>
                          <li>Ultimate</li>
                          <li>Skill</li>
                          <li>Heavy Attack: Detonate</li>
                          <li>Heavy Attack: Detonate</li>
                          <li>Echo (Impermanance Heron Swap-Cancel)</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6>BASIC ATK CONCERTO ROTATION</h6>
                        <ul>
                          <li>Intro</li>
                          <li>Basic Attack P3</li>
                          <li>Basic Attack P4</li>
                          <li>Basic Attack P5</li>
                          <li>Ultimate</li>
                          <li>Skill</li>
                          <li>Heavy Attack: Detonate</li>
                          <li>Echo (Impermanance Heron Swap-Cancel)</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'mortefi' && (
                    <>
                      <blockquote>
                        <ul>
                          <li>Intro</li>
                          <li>Skill: Passionate Variation</li>
                          <li>Skill: Fury Fugue</li>
                          <li>Basic P1</li>
                          <li>Basic P2</li>
                          <li>Basic P3</li>
                          <li>Basic P4</li>
                          <li>Skill: Fury Fugue</li>
                          <li>Ultimate</li>
                          <li>Echo (Impermanance Heron Swap-Cancel)</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'lingyang' && (
                    <>
                      <blockquote>
                        <h6>BURST COMBO</h6>
                        <p>
                          Lingyang’s combo is incredibly simple, just make sure
                          you have full energy available on him before Swapping
                          to him and enter with his Intro to generate a portion
                          of his Forte Gauge required. Follow it up with his
                          Ultimate and perform a Heavy Attack to become
                          airborne.
                        </p>
                        <p>
                          While airborne, alternate between using a single Basic
                          Attack and a single Resonance Skill to take advantage
                          of his Inherent Skill: Diligent Practice. Ensure that
                          you do not chain 2 of same type together while
                          alternating between them, as it is very easy to
                          accidentally do the same and lose damage overall.
                        </p>
                        <p>
                          Under ideal circumstances, you can execute 9
                          independant attacks during his Ultimate duration, but
                          if you get Stunned or use Dodge, this number will
                          reduce. While the damage loss from this isn’t a
                          disaster, you may need to spend extra time building
                          energy via other characters to make up for it. While
                          his Ultimate is on cooldown or he lacks the energy,
                          utilize that time on the rest of the team recharging
                          their Concerto Energy and preparing buffs for his next
                          burst combo.
                        </p>
                        <ul>
                          <li>
                            Echo (Use before combo but refer to Echo
                            recommendations for exact specifics for your chosen
                            Echo)
                          </li>
                          <li>Intro</li>
                          <li>Ultimate</li>
                          <li>
                            Heavy Attack: Glorious Plunge (Used to enter Forte
                            and become airborne)
                          </li>
                          <li>Basic Attack: Feral Gyrate P1</li>
                          <li>Skill: Mountain Roamer</li>
                          <li>Basic Attack: Feral Gyrate P2</li>
                          <li>Skill: Mountain Roamer</li>
                          <li>Basic Attack: Feral Gyrate P1</li>
                          <li>Skill: Mountain Roamer</li>
                          <li>Basic Attack: Feral Gyrate P2</li>
                          <li>Skill: Mountain Roamer</li>
                          <li>Basic Attack: Feral Gyrate P1</li>
                          <li>Basic Attack: Stormy Kicks</li>
                          <li>Mid-Air Attack: Tail Strike</li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'yinlin' && (
                    <>
                      <blockquote>
                        <h6>STANDARD ROTATION</h6>
                        <ul>
                          <li>Echo (Swap-Cancel)</li>
                          <li>Intro</li>
                          <li>Skill: Magnetic Roar</li>
                          <li>Skill: Lightning Execution (Swap-Cancel)</li>
                          <li>Basic P2</li>
                          <li>Basic P3 </li>
                          <li>Basic P4 (Swap-Cancel)</li>
                          <li>Ultimate</li>
                          <li>Chameleon Cipher (Swap-Cancel)</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Character build</Accordion.Header>
                <Accordion.Body>
                  <p>
                    And here are the Echoes and Weapons used in the
                    calculations.
                  </p>
                  <div className="build-tips damage">
                    <div className={`single-item without-notes ${element}`}>
                      <WWWeaponEntry
                        name={dpsRanking.weapon}
                        level={[Number(dpsRanking.weapon_S)]}
                        mode="icon"
                        onProfile
                      />
                    </div>
                    <div className={`single-item without-notes ${element}`}>
                      {dpsRanking.set_2 != '' ? (
                        <div className="double-set">
                          <WWSet name={dpsRanking.set_1} mode="profile" />
                          <WWSet name={dpsRanking.set_2} mode="profile" />
                        </div>
                      ) : (
                        <>
                          <WWSet name={dpsRanking.set_1} mode="profile" />
                        </>
                      )}
                    </div>
                    <div className={`single-item without-notes ${element}`}>
                      <WWEchoEntry
                        name={dpsRanking.echo_main}
                        level={[5]}
                        mode="icon"
                        onProfile
                      />
                    </div>
                  </div>
                  <div className="main-stats damage">
                    <div className="box">
                      <div className="stats-inside">
                        <strong className="Overlord">4 cost</strong>
                      </div>
                      <div className="list-stats">
                        <WWStat stat={dpsRanking.echo_4} />
                      </div>
                    </div>
                    <div className="box">
                      <div className="stats-inside">
                        <strong className="Elite">3 cost</strong>
                      </div>
                      <div className="list-stats">
                        <WWStat stat={dpsRanking.echo_3} />
                      </div>
                    </div>
                    <div className="box">
                      <div className="stats-inside">
                        <strong className="Elite">3 cost</strong>
                      </div>
                      <div className="list-stats">
                        <WWStat stat={dpsRanking.echo_3a} />
                      </div>
                    </div>
                    <div className="box">
                      <div className="stats-inside">
                        <strong className="Common">1 cost</strong>
                      </div>
                      <div className="list-stats">
                        <WWStat stat={dpsRanking.echo_1} />
                      </div>
                    </div>
                    <div className="box">
                      <div className="stats-inside">
                        <strong className="Common">1 cost</strong>
                      </div>
                      <div className="list-stats">
                        <WWStat stat={dpsRanking.echo_1a} />
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col>
                      <div className="box sub-stats damage">
                        <p>
                          <span>Substats:</span> {dpsRanking.substats}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        </>
      )}
    </>
  );
};
