export const WWCharactersDMG = [
  {
    id: 0,
    name: 'Rover (Spectro)',
    slug: 'rover-spectro',
    disclaimer: '',
    dmg: {
      damage: 120893,
      damage_e1: 131334,
      damage_e2: 144726,
      damage_e3: 144726,
      damage_e4: 144726,
      damage_e5: 153778,
      damage_e6: 169930
    },
    dps: {
      damage: 21732,
      damage_e1: 23608,
      damage_e2: 26016,
      damage_e3: 26016,
      damage_e4: 26016,
      damage_e5: 27643,
      damage_e6: 30546
    },
    time: 5.56,
    total_dmg: 177713,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT DMG',
    echo_3: 'Spectro DMG',
    echo_3a: 'Spectro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 1,
    name: 'Jiyan',
    slug: 'jiyan',
    disclaimer: '',
    dmg: {
      damage: 360023,
      damage_e1: 360023,
      damage_e2: 396163,
      damage_e3: 495232,
      damage_e4: 531450,
      damage_e5: 630781,
      damage_e6: 816994
    },
    dps: {
      damage: 30067,
      damage_e1: 30067,
      damage_e2: 33085,
      damage_e3: 41359,
      damage_e4: 44384,
      damage_e5: 52679,
      damage_e6: 68231
    },
    time: 12.17,
    total_dmg: 407865,
    echo_main: 'Nightmare: Feilian Beringal',
    echo_4: 'CRIT Rate',
    echo_3: 'Aero DMG',
    echo_3a: 'Aero DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (27%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Sierra Gale',
    set_2: '',
    weapon: 'Lustrous Razor',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 2,
    name: 'Danjin',
    slug: 'danjin',
    disclaimer: '',
    dmg: {
      damage: 122330,
      damage_e1: 134677,
      damage_e2: 147755,
      damage_e3: 153398,
      damage_e4: 157556,
      damage_e5: 177688,
      damage_e6: 190505
    },
    dps: {
      damage: 16075,
      damage_e1: 17697,
      damage_e2: 19416,
      damage_e3: 20157,
      damage_e4: 20704,
      damage_e5: 23349,
      damage_e6: 25033
    },
    time: 7.61,
    total_dmg: 259897,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT DMG',
    echo_3: 'Havoc DMG',
    echo_3a: 'Havoc DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 4,
    name: 'Calcharo',
    slug: 'calcharo',
    disclaimer: '',
    dmg: {
      damage: 391539,
      damage_e1: 391539,
      damage_e2: 392768,
      damage_e3: 428454,
      damage_e4: 461646,
      damage_e5: 466383,
      damage_e6: 509120
    },
    dps: {
      damage: 29219,
      damage_e1: 29219,
      damage_e2: 29311,
      damage_e3: 31974,
      damage_e4: 34451,
      damage_e5: 34805,
      damage_e6: 37994
    },
    time: 13.4,
    total_dmg: 389113,
    echo_main: 'Nightmare: Thundering Mephis',
    echo_4: 'CRIT Rate',
    echo_3: 'Electro DMG',
    echo_3a: 'Electro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Void Thunder',
    set_2: '',
    weapon: 'Lustrous Razor',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 5,
    name: 'Encore',
    slug: 'encore',
    disclaimer: '',
    dmg: {
      damage: 305442,
      damage_e1: 316675,
      damage_e2: 316675,
      damage_e3: 335338,
      damage_e4: 360575,
      damage_e5: 366627,
      damage_e6: 394003
    },
    dps: {
      damage: 28675,
      damage_e1: 29729,
      damage_e2: 29729,
      damage_e3: 31481,
      damage_e4: 33850,
      damage_e5: 34419,
      damage_e6: 36989
    },
    time: 10.65,
    total_dmg: 368173,
    echo_main: 'Nightmare: Inferno Rider',
    echo_4: 'CRIT Rate',
    echo_3: 'Fusion DMG',
    echo_3a: 'Fusion DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Molten Rift',
    set_2: '',
    weapon: 'Cosmic Ripples',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 6,
    name: 'Yinlin',
    slug: 'yinlin',
    disclaimer: '',
    dmg: {
      damage: 156823,
      damage_e1: 166738,
      damage_e2: 162131,
      damage_e3: 194105,
      damage_e4: 207276,
      damage_e5: 227739,
      damage_e6: 308419
    },
    dps: {
      damage: 42078,
      damage_e1: 44738,
      damage_e2: 57351,
      damage_e3: 68661,
      damage_e4: 73320,
      damage_e5: 80559,
      damage_e6: 109098
    },
    time: 3.73,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Electro DMG',
    echo_3a: 'Electro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Cosmic Ripples',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 8,
    name: 'Mortefi',
    slug: 'mortefi',
    disclaimer:
      "Mortefi does more damage depending on who he is supporting due to how his ultimate works. His ultimate can fire a maximum amount of 60 coordinated attacks at S0 in a literal perfect world when being abused by a Heavy Attack spammer, but this is not realistic, so we've used 35 coordinated attacks instead. This is how many you could expect to get when using Jiyan. Mortefi's S1 doesn't scale particularly well with Jiyan which is how we've represented but works much much better with characters like Danjin compared to what is shown here.",
    dmg: {
      damage: 115511,
      damage_e1: 121579,
      damage_e2: 121579,
      damage_e3: 126537,
      damage_e4: 176349,
      damage_e5: 183314,
      damage_e6: 193210
    },
    dps: {
      damage: 20554,
      damage_e1: 21633,
      damage_e2: 21633,
      damage_e3: 22515,
      damage_e4: 31379,
      damage_e5: 32618,
      damage_e6: 34379
    },
    time: 5.62,
    total_dmg: 118682,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Fusion DMG',
    echo_3a: 'Fusion DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (9.6%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Static Mist',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 9,
    name: 'Aalto',
    slug: 'aalto',
    disclaimer: '',
    dmg: {
      damage: 90084,
      damage_e1: 90084,
      damage_e2: 92227,
      damage_e3: 124351,
      damage_e4: 128402,
      damage_e5: 128402,
      damage_e6: 133721
    },
    dps: {
      damage: 24003,
      damage_e1: 24003,
      damage_e2: 24574,
      damage_e3: 33134,
      damage_e4: 34213,
      damage_e5: 34213,
      damage_e6: 35631
    },
    time: 3.75,
    total_dmg: 110727,
    echo_main: 'Nightmare: Feilian Beringal',
    echo_4: 'CRIT DMG',
    echo_3: 'Aero DMG',
    echo_3a: 'Aero DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (27%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Sierra Gale',
    set_2: '',
    weapon: 'Static Mist',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 10,
    name: 'Lingyang',
    slug: 'lingyang',
    disclaimer: '',
    dmg: {
      damage: 351758,
      damage_e1: 351758,
      damage_e2: 351758,
      damage_e3: 367346,
      damage_e4: 393635,
      damage_e5: 406294,
      damage_e6: 432131
    },
    dps: {
      damage: 26508,
      damage_e1: 26508,
      damage_e2: 26508,
      damage_e3: 27682,
      damage_e4: 29664,
      damage_e5: 30617,
      damage_e6: 32565
    },
    time: 13.47,
    total_dmg: 343072,
    echo_main: 'Mech Abomination',
    echo_4: 'CRIT Rate',
    echo_3: 'Glacio DMG',
    echo_3a: 'Glacio DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (9.6%)',
    finalstats: '',
    set_1: 'Lingering Tunes',
    set_2: '',
    weapon: 'Abyss Surges',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 11,
    name: 'Sanhua',
    slug: 'sanhua',
    disclaimer: '',
    dmg: {
      damage: 93795,
      damage_e1: 93795,
      damage_e2: 93795,
      damage_e3: 107223,
      damage_e4: 116604,
      damage_e5: 121581,
      damage_e6: 123578
    },
    dps: {
      damage: 17731,
      damage_e1: 17731,
      damage_e2: 17731,
      damage_e3: 20269,
      damage_e4: 22042,
      damage_e5: 22983,
      damage_e6: 23361
    },
    time: 5.29,
    total_dmg: 107866,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Glacio DMG',
    echo_3a: 'Glacio DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 12,
    name: 'Chixia',
    slug: 'chixia',
    disclaimer:
      "Due to Chixia's reliance on channeled abilities to do damage, achieving this damage output against enemies that move around or force you to dodge is nearly impossible.",
    dmg: {
      damage: 257500,
      damage_e1: 270391,
      damage_e2: 270391,
      damage_e3: 277449,
      damage_e4: 277449,
      damage_e5: 307919,
      damage_e6: 307919
    },
    dps: {
      damage: 29162,
      damage_e1: 30622,
      damage_e2: 30622,
      damage_e3: 31421,
      damage_e4: 31421,
      damage_e5: 34872,
      damage_e6: 34872
    },
    time: 8.83,
    total_dmg: 339243,
    echo_main: 'Nightmare: Inferno Rider',
    echo_4: 'CRIT Rate',
    echo_3: 'Fusion DMG',
    echo_3a: 'Fusion DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Molten Rift',
    set_2: '',
    weapon: 'Static Mist',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 13,
    name: 'Rover (Havoc)',
    slug: 'rover-havoc',
    disclaimer: '',
    dmg: {
      damage: 318979,
      damage_e1: 326821,
      damage_e2: 326821,
      damage_e3: 326821,
      damage_e4: 356337,
      damage_e5: 370391,
      damage_e6: 434236
    },
    dps: {
      damage: 28228,
      damage_e1: 28922,
      damage_e2: 28922,
      damage_e3: 28922,
      damage_e4: 31534,
      damage_e5: 32778,
      damage_e6: 38428
    },
    time: 11.3,
    total_dmg: 363772,
    echo_main: 'Dreamless',
    echo_4: 'CRIT DMG',
    echo_3: 'Havoc DMG',
    echo_3a: 'Havoc DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (50%), CRIT Rate (37.5%), CRIT DMG (75%)',
    finalstats: '',
    set_1: 'Havoc Eclipse',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 14,
    name: 'Yuanwu',
    slug: 'yuanwu',
    disclaimer: '',
    dmg: {
      damage: 78379,
      damage_e1: 78379,
      damage_e2: 78379,
      damage_e3: 97058,
      damage_e4: 97058,
      damage_e5: 103122,
      damage_e6: 115310
    },
    dps: {
      damage: 26470,
      damage_e1: 26470,
      damage_e2: 26470,
      damage_e3: 32779,
      damage_e4: 32779,
      damage_e5: 34827,
      damage_e6: 38943
    },
    time: 2.96,
    echo_main: 'Nightmare: Tempest Mephis',
    echo_4: 'CRIT Rate',
    echo_3: 'Electro DMG',
    echo_3a: 'Electro DMG',
    echo_1: 'DEF%',
    echo_1a: 'DEF%',
    substats:
      'DEF (34.17%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Void Thunder',
    set_2: '',
    weapon: 'Amity Accord',
    weapon_S: 5,
    has_alt_dmg: false
  },
  {
    id: 15,
    name: 'Jinhsi',
    slug: 'jinhsi',
    disclaimer: '',
    dmg: {
      damage: 534940,
      damage_e1: 615860,
      damage_e2: 615860,
      damage_e3: 738093,
      damage_e4: 790193,
      damage_e5: 935143,
      damage_e6: 1123346
    },
    dps: {
      damage: 39184,
      damage_e1: 45111,
      damage_e2: 45111,
      damage_e3: 54065,
      damage_e4: 57881,
      damage_e5: 68499,
      damage_e6: 82284
    },
    time: 13.65,
    total_dmg: 594378,
    echo_main: 'Jué',
    echo_4: 'CRIT Rate',
    echo_3: 'Spectro DMG',
    echo_3a: 'Spectro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (27%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Celestial Light',
    set_2: '',
    weapon: 'Lustrous Razor',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 16,
    name: 'Changli',
    slug: 'changli',
    disclaimer: '',
    dmg: {
      damage: 359942,
      damage_e1: 368921,
      damage_e2: 425289,
      damage_e3: 458119,
      damage_e4: 495890,
      damage_e5: 599498,
      damage_e6: 747012
    },
    dps: {
      damage: 36811,
      damage_e1: 37730,
      damage_e2: 43494,
      damage_e3: 46852,
      damage_e4: 50715,
      damage_e5: 61311,
      damage_e6: 76397
    },
    time: 9.78,
    total_dmg: 413378,
    echo_main: 'Nightmare: Inferno Rider',
    echo_4: 'CRIT DMG',
    echo_3: 'Fusion DMG',
    echo_3a: 'Fusion DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Molten Rift',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 17,
    name: 'Zhezhi',
    slug: 'zhezhi',
    disclaimer: '',
    dmg: {
      damage: 169606,
      damage_e1: 180792,
      damage_e2: 201389,
      damage_e3: 230502,
      damage_e4: 243441,
      damage_e5: 295729,
      damage_e6: 364044
    },
    dps: {
      damage: 24160,
      damage_e1: 25754,
      damage_e2: 28688,
      damage_e3: 32835,
      damage_e4: 34678,
      damage_e5: 42127,
      damage_e6: 51858
    },
    time: 7.02,
    total_dmg: 413378,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Glacio DMG',
    echo_3a: 'Glacio DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (9.6%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Cosmic Ripples',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 18,
    name: 'Xiangli Yao',
    slug: 'xiangli-yao',
    disclaimer: '',
    dmg: {
      damage: 407951,
      damage_e1: 464311,
      damage_e2: 509410,
      damage_e3: 574197,
      damage_e4: 606033,
      damage_e5: 751223,
      damage_e6: 947675
    },
    dps: {
      damage: 31101,
      damage_e1: 35398,
      damage_e2: 38836,
      damage_e3: 43775,
      damage_e4: 46202,
      damage_e5: 57271,
      damage_e6: 72248
    },
    time: 13.12,
    total_dmg: 422465,
    echo_main: 'Nightmare: Thundering Mephis',
    echo_4: 'CRIT Rate',
    echo_3: 'Electro DMG',
    echo_3a: 'Electro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (9.6%)',
    finalstats: '',
    set_1: 'Void Thunder',
    set_2: '',
    weapon: 'Abyss Surges',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 19,
    name: 'The Shorekeeper',
    slug: 'the-shorekeeper',
    disclaimer: '',
    dmg: {
      damage: 70111,
      damage_e1: 73041,
      damage_e2: 75008,
      damage_e3: 66051,
      damage_e4: 66051,
      damage_e5: 66051,
      damage_e6: 224877
    },
    dps: {
      damage: 16331,
      damage_e1: 17014,
      damage_e2: 17472,
      damage_e3: 52421,
      damage_e4: 52421,
      damage_e5: 52421,
      damage_e6: 178474
    },
    time: 4.29,
    total_dmg: 177713,
    echo_main: 'Fallacy of No Return',
    echo_4: 'CRIT DMG',
    echo_3: 'Energy Regen',
    echo_3a: 'Spectro DMG',
    echo_1: 'HP%',
    echo_1a: 'HP%',
    substats: 'Energy Regen (48%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Rejuvenating Glow',
    set_2: '',
    weapon: 'Variation',
    weapon_S: 5,
    has_alt_dmg: false
  },
  {
    id: 20,
    name: 'Camellya',
    slug: 'camellya',
    disclaimer: '',
    dmg: {
      damage: 476781,
      damage_e1: 528689,
      damage_e2: 643778,
      damage_e3: 729661,
      damage_e4: 800330,
      damage_e5: 871998,
      damage_e6: 1640538
    },
    dps: {
      damage: 30756,
      damage_e1: 34105,
      damage_e2: 41529,
      damage_e3: 47069,
      damage_e4: 51628,
      damage_e5: 56251,
      damage_e6: 72532
    },
    time: 15.5,
    total_dmg: 506752,
    echo_main: 'Nightmare: Crownless',
    echo_4: 'CRIT Rate',
    echo_3: 'Havoc DMG',
    echo_3a: 'Havoc DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Havoc Eclipse',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 21,
    name: 'Lumi',
    slug: 'lumi',
    disclaimer: '',
    dmg: {
      damage: 133574,
      damage_e1: 133574,
      damage_e2: 137769,
      damage_e3: 143594,
      damage_e4: 151813,
      damage_e5: 151813,
      damage_e6: 155798
    },
    dps: {
      damage: 10762,
      damage_e1: 10762,
      damage_e2: 11100,
      damage_e3: 11569,
      damage_e4: 12231,
      damage_e5: 12231,
      damage_e6: 12552
    },
    time: 12.41,
    total_dmg: 148416,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Electric DMG',
    echo_3a: 'Electric DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats:
      'ATK (18%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (38.4%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Lustrous Razor',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 22,
    name: 'Carlotta',
    slug: 'carlotta',
    disclaimer: '',
    dmg: {
      damage: 527181,
      damage_e1: 575821,
      damage_e2: 695275,
      damage_e3: 878223,
      damage_e4: 949645,
      damage_e5: 980400,
      damage_e6: 1270954
    },
    dps: {
      damage: 38315,
      damage_e1: 41850,
      damage_e2: 50532,
      damage_e3: 63829,
      damage_e4: 69020,
      damage_e5: 71255,
      damage_e6: 92373
    },
    time: 13.76,
    total_dmg: 527181,
    echo_main: 'Sentry Construct',
    echo_4: 'CRIT DMG',
    echo_3: 'Glacio DMG',
    echo_3a: 'Glacio DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Frosty Resolve',
    set_2: '',
    weapon: 'Static Mist',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 23,
    name: 'Jianxin',
    slug: 'jianxin',
    disclaimer: '',
    dmg: {
      damage: 156295,
      damage_e1: 196794,
      damage_e2: 196794,
      damage_e3: 196794,
      damage_e4: 215544,
      damage_e5: 215544,
      damage_e6: 181471
    },
    dps: {
      damage: 15656,
      damage_e1: 17426,
      damage_e2: 17426,
      damage_e3: 17426,
      damage_e4: 19087,
      damage_e5: 19087,
      damage_e6: 22639
    },
    time: 9.98,
    total_dmg: 407865,
    echo_main: 'Nightmare: Feilian Beringal',
    echo_4: 'CRIT Rate',
    echo_3: 'Aero DMG',
    echo_3a: 'Aero DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (27%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Sierra Gale',
    set_2: '',
    weapon: 'Abyss Surges',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 24,
    name: 'Yangyang',
    slug: 'yangyang',
    disclaimer: '',
    dmg: {
      damage: 92257,
      damage_e1: 99827,
      damage_e2: 99827,
      damage_e3: 102648,
      damage_e4: 109682,
      damage_e5: 125459,
      damage_e6: 127317
    },
    dps: {
      damage: 13332,
      damage_e1: 14426,
      damage_e2: 14426,
      damage_e3: 14834,
      damage_e4: 15850,
      damage_e5: 18130,
      damage_e6: 18398
    },
    time: 6.92,
    total_dmg: 407865,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Aero DMG',
    echo_3a: 'Aero DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats:
      'ATK (18%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (38.4%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 25,
    name: 'Roccia',
    slug: 'roccia',
    disclaimer: '',
    dmg: {
      damage: 178798,
      damage_e1: 168608,
      damage_e2: 192090,
      damage_e3: 228621,
      damage_e4: 268185,
      damage_e5: 280797,
      damage_e6: 383244
    },
    dps: {
      damage: 26871,
      damage_e1: 26125,
      damage_e2: 29763,
      damage_e3: 35423,
      damage_e4: 41553,
      damage_e5: 43507,
      damage_e6: 51072
    },
    time: 6.65,
    total_dmg: 407865,
    echo_main: 'Nightmare: Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Havoc DMG',
    echo_3a: 'Havoc DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats:
      'ATK (36%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (19.2%)',
    finalstats: '',
    set_1: 'Midnight Veil',
    set_2: '',
    weapon: 'Abyss Surges',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 26,
    name: 'Phoebe',
    slug: 'phoebe',
    disclaimer: '',
    dmg: {
      damage: 587832,
      damage_e1: 641618,
      damage_e2: 770076,
      damage_e3: 961420,
      damage_e4: 1068244,
      damage_e5: 1125361,
      damage_e6: 1413613
    },
    dps: {
      damage: 35200,
      damage_e1: 38420,
      damage_e2: 46112,
      damage_e3: 57570,
      damage_e4: 63967,
      damage_e5: 67387,
      damage_e6: 83646
    },
    time: 16.7,
    total_dmg: 594378,
    echo_main: 'Nightmare: Mourning Aix',
    echo_4: 'CRIT Rate',
    echo_3: 'Spectro DMG',
    echo_3a: 'Spectro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Eternal Radiance',
    set_2: '',
    weapon: 'Cosmic Ripples',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 27,
    name: 'Brant',
    slug: 'brant',
    disclaimer: '',
    dmg: {
      damage: 244013,
      damage_e1: 308218,
      damage_e2: 400926,
      damage_e3: 480309,
      damage_e4: 480309,
      damage_e5: 505979,
      damage_e6: 612133
    },
    dps: {
      damage: 29417,
      damage_e1: 35831,
      damage_e2: 48700,
      damage_e3: 58635,
      damage_e4: 58635,
      damage_e5: 61700,
      damage_e6: 74967
    },
    time: 8.3,
    total_dmg: 244013,
    echo_main: 'Nightmare: Inferno Rider',
    echo_4: 'CRIT DMG',
    echo_3: 'Energy Regen',
    echo_3a: 'Energy Regen',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'Energy Regen (48%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Molten Rift',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 28,
    name: 'Cantarella',
    slug: 'cantarella',
    disclaimer: '',
    dmg: {
      damage: 216635,
      damage_e1: 258104,
      damage_e2: 341206,
      damage_e3: 407208,
      damage_e4: 407208,
      damage_e5: 410459,
      damage_e6: 501676
    },
    dps: {
      damage: 25805,
      damage_e1: 30745,
      damage_e2: 40644,
      damage_e3: 48506,
      damage_e4: 48506,
      damage_e5: 48893,
      damage_e6: 59759
    },
    time: 8.4,
    total_dmg: 244013,
    echo_main: 'Lorelei',
    echo_4: 'CRIT DMG',
    echo_3: 'Havoc DMG',
    echo_3a: 'Havoc DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'Energy Regen (28%), CRIT Rate (42%), CRIT DMG (84%), ATK (27%)',
    finalstats: '',
    set_1: 'Midnight Veil',
    set_2: '',
    weapon: 'Cosmic Ripples',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 29,
    name: 'Rover (Aero)',
    slug: 'rover-aero',
    disclaimer: '',
    dmg: {
      damage: 115655,
      damage_e1: 115655,
      damage_e2: 115655,
      damage_e3: 125347,
      damage_e4: 131872,
      damage_e5: 136632,
      damage_e6: 150580
    },
    dps: {
      damage: 15858,
      damage_e1: 15858,
      damage_e2: 15858,
      damage_e3: 17187,
      damage_e4: 18082,
      damage_e5: 18735,
      damage_e6: 20647
    },
    time: 7.29,
    total_dmg: 244013,
    echo_main: 'Fallacy of No Return',
    echo_4: 'CRIT DMG',
    echo_3: 'Aero DMG',
    echo_3a: 'Aero DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'CRIT Rate (42%), CRIT DMG (84%), ATK (45%)',
    finalstats: '',
    set_1: 'Rejuvenating Glow',
    set_2: '',
    weapon: "Bloodpact's Pledge",
    weapon_S: 1,
    has_alt_dmg: false
  }
];
