/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './ww-weapon-box.scss';

interface IProps {
  name: string;
  char?: string;
  supere?: string;
}

export const WWWeaponNotes: React.FC<IProps> = ({ name, char, supere }) => {
  return (
    <>
      {/* Pistols */}
      {name === 'The Last Dance' && (
        <div className="information with-padding">
          Carlotta's Signature and a solid choice for most pistol based
          resonators due to its decent base damage, very high CRIT DM% and
          permanent ATK% boost. Far more effective though for resonators that
          deal some or the majority of their damage through Resonance Skill
          damaging abilites.{' '}
          {char === 'carlotta' && (
            <>
              Best in slot by far for Carlotta due to most of her abilities
              dealing Resonance Skill typed damage.
            </>
          )}
        </div>
      )}
      {name === 'Static Mist' && (
        <div className="information with-padding">
          Incredibly potent option offering main stat CRIT RATE, great base
          damage and a good amount of energy recharge allowing for sub stats to
          be used elsewhere. Also offers a supportive ability granting the next
          character to switch in an ATK boost granting it extra value for Hybrid
          and Support characters.{' '}
          {char === 'carlotta' && (
            <>
              Carlotta doesn't make great use of the supportive ability on this
              weapon nor does she desperately need the Energy Regen it grants,
              but the base stats are good enough to make it a solid choice.
            </>
          )}
        </div>
      )}
      {name === 'Novaburst' && (
        <div className="information with-padding">
          Gun usable by all resonators, offering good bonuses to ATK but reliant
          on dodging 3 times initially (with continual dodges after that).
          Should only be used by those willing to perform dodges at the start of
          their rotation and consistently after. Calculations assume you perform
          dodges as soon as possible and maintain the buff.
        </div>
      )}
      {name === 'Undying Flame' && (
        <div className="information with-padding">
          Strong gun option for resonators that deal primarily Resonance Skill
          DMG with little to no conditions to worry about, thanks to its effect
          being tied to Intro skills. Grants a Resonance Skill DMG bonus that
          lasts for 15 seconds on using Intro - long enough for most Main DPS to
          finish their burst window making this a strong consistent option.
        </div>
      )}
      {name === 'Pistols#26' && (
        <div className="information with-padding">
          Generic gun option usable by all characters but with a significant
          ramp-up time on its bonus. Also requires the player not to be hit in
          order to extract full value. If you do get hit this weapon will fall
          behind (calculations assume you don't get hit once).
        </div>
      )}
      {name === 'Thunderbolt' && (
        <div className="information with-padding">
          Powerful Gun option for Resonance Skill focused damage characters, but
          with a heavy conditional required for activation. Grants solid base
          damage and ATK% via main stats. Also offers an incredibly potent
          stackable Resonance Skill DMG% bonus that requires the users
          consistently use Basic Attacks to maintain.{' '}
          {char === 'carlotta' && (
            <>
              To build and maintain the stacks of this weapon, additional
              attacks have been added and factored into Carlotta's calculations
              in this case.
            </>
          )}
        </div>
      )}
      {name === 'Romance in Farewell' && (
        <div className="information with-padding">
          Debuff themed weapon for Pistol Resonators that grants a stackable
          bonus when striking enemies afflicted with any status ailment. Buff is
          quick to stack and easy to maintain, assuming one or multiple
          resonators on the team have access to some form of debuff.{' '}
          {char === 'carlotta' && (
            <>
              Carlotta can apply her own debuff via her kit making this weapons
              additional ability easy to stack and maintain.
            </>
          )}
        </div>
      )}
      {name === 'Pistols of Night' && (
        <div className="information with-padding">
          Easily accessible 3★ weapon that despite having lower base attack
          makes up for it with a high ATK% bonus at higher dupes. It does not
          have enough stats to compete with 4-star weapons but is a reasonable
          option if it's all you have. The only downside is that the buff only
          lasts 10 seconds, meaning it may fall off in the hands of a Main DPS
          character who takes up more field time.
        </div>
      )}
      {name === 'Cadenza' && (
        <div className="information with-padding">
          A utility-focused weapon prioritizing lightning-fast Concerto
          rotations over raw output with the ability to transform entire units'
          action priorities under the right circumstances. Not always a good
          choice but when implemented correctly makes all the difference. Also
          offers an immense amount of Energy Regen further accelerating
          rotations and supportive capabilities.
        </div>
      )}
      {name === 'Pistols of Voyager' && (
        <div className="information with-padding">
          An easily accessible supportive option with a reasonable amount of
          Energy Regen. It also grants the unique ability to gain a sizable
          chunk of raw Resonance Energy once every 20 seconds. For most
          character's rotations in teams, this bonus will be ready every time
          it's their turn to switch-in, making this weapon a great choice for
          any character who doesn't need damage but needs their Ultimate fast
          and has trouble accessing it otherwise. The flat energy gain is more
          powerful the less energy the character and team they are a part of can
          generate.
        </div>
      )}
      {/* Broadblade */}
      {name === 'Ages of Harvest' && (
        <>
          {char === 'jinhsi' && (
            <div className="information with-padding">
              Jinhsi's signature and best in slot weapon granting an exceptional
              amount of Crit Rate, Resonance Skill DMG% and DMG% all stats that
              amplify Jinhsi's damage tremendously. This weapon handily beats
              out all of the competition without trouble. One thing to note when
              using this weapon is that due to the sheer amount of DMG% Jinhsi
              has from her kit, echoes and this weapon it means she can
              comparatively gain higher value than usual when stacking ATK %,
              specifically on her 3 cost echoes instead of Spectro %.
            </div>
          )}
          {char === 'calcharo' && (
            <div className="information with-padding">
              A very competitive option to his best in slot if you didn't pull
              it on the standard banner. The high base ATK, as well as the 24%
              crit rate and 12% DMG bonus substats are very nice, especially if
              you need your weapon to even out your crit ratios, in which builds
              it might outdo Lustrous Razor; and while barely used on Calcharo
              at all, the Resonance Skill DMG boost is always a small damage
              increase.{' '}
            </div>
          )}
          {char === 'jiyan' && (
            <div className="information with-padding">
              A fantastic (while not best) weapon choice for Jiyan, that he can
              very much use if you have Jinhsi on your account also, who
              benefits more from having her signature than Jiyan does. The high
              base ATK, 24% CRIT Rate and 12% DMG Bonus it provides are welcome
              on any Broadblade DPS, and Jiyan specifically makes decent (while
              not tremendous) use of the Skill DMG Bonus the weapon provides as
              well.
            </div>
          )}
          {char === 'lumi' && (
            <div className="information with-padding">
              Jinhsi's weapon is currently also the best broadblade option for
              Lumi's damage, who benefits a great amount from the Skill DMG
              Bonus the weapon provides, as it boosts a good chunk of her
              rotations damage. The high base ATK, 24% CRIT Rate and 12% DMG
              Bonus the weapon additionally provides are also all stats that are
              welcome for her, making it a very high damage option
            </div>
          )}
          {char != 'jinhsi' &&
            char != 'calcharo' &&
            char != 'jiyan' &&
            char != 'lumi' && <div className="information with-padding">-</div>}
        </>
      )}
      {name === 'Verdant Summit' && (
        <div className="information with-padding">
          Jiyan's signature weapon that's not only fantastic for him but also
          usable on all damage-focused Broadblade users. Offers a huge main stat
          of CRIT DMG% granting excellent scaling, 12% permanent generic DMG%
          and up to 48% increased Heavy ATK DMG% after using 1/2 skills and or a
          Resonance Liberation.
        </div>
      )}
      {name === 'Lustrous Razor' && (
        <div className="information with-padding">
          Top Broadblade option only in competition with some 4★ weapons with
          multiple copies invested or limited 5★ weapons. Offers high base ATK,
          ATK%, a good amount of Energy Regen to save at least 1 sub-stat on
          gear to be reallocated to better uses and a stackable Resonance
          Liberation ATK DMG% bonus that ramps up as you use Resonance Skills.
        </div>
      )}
      {char === 'jinhsi' ? (
        <>
          {name === 'Autumntrace' && (
            <div className="information with-padding">
              Jinhsi cannot easily stack Autumntrace's bonus ability granting
              additional attack as she doesn't make use of her standard basic or
              heavy attacks and her enhanced basic attacks are treated instead
              as Resonance Skill damage thus not counting. Autumntrace is still
              usable but just less potent than on usual.
            </div>
          )}
        </>
      ) : (
        <>
          {name === 'Autumntrace' && (
            <div className="information with-padding">
              Strongest 4★ Broadsword option, granting main stat CRIT RATE for
              excellent scaling and an incredibly easy to access bonus ability
              which grants a stacking ATK% buff that only requires you to use
              Basic or Heavy attacks during your rotation.
            </div>
          )}
        </>
      )}
      {name === 'Helios Cleaver' && (
        <div className="information with-padding">
          A generic option, granting a stacking ATK% buff after using your
          Resonance Skill that starts at 0 and grants 1 stack every 2 seconds up
          to 4 stacks - reaching a maximum after 8 seconds. Buff resets shortly
          after the stack cap limit is reached after which you'll have to repeat
          the cycle anew. Falls behind other options due to it's buff being
          inconsistent but still a viable option if you don't have better.
        </div>
      )}
      {name === 'Broadblade#41' && (
        <div className="information with-padding">
          Another generic Broadblade option with a mix of utility and offense,
          granting a large amount of Energy Regen as a main stat. Also a
          reasonably strong and easy to maintain bonus ability that offers an
          ATK% buff as long as you remain above 80% HP. Our calculations assume
          you'll stay above 80% for the whole fight, if you can't you can lower
          this weapons performance.
        </div>
      )}
      {name === 'Broadblade of Night' && (
        <div className="information with-padding">
          Easily accessible 3★ weapon that despite having lower base attack
          makes up for it with a high ATK% bonus at higher dupes. It does not
          have enough stats to compete with 4-star weapons but is a reasonable
          option if it's all you have. The only downside is that the buff only
          lasts 10 seconds, meaning it may fall off in the hands of a Main DPS
          character who takes up more field time.
        </div>
      )}
      {name === 'Discord' && (
        <>
          {char === 'lumi' && (
            <div className="information with-padding">
              Discord is good for players looking to smooth out Lumi's rotation
              with some extra concerto and also for those hungry for Energy
              Regeneration for her.
            </div>
          )}
          {char === 'taoqi' && (
            <div className="information with-padding">
              A close-to necessary option for Taoqi if you have it, enabling her
              to cut down on a lot of her annoying rotation time issues. The
              higher the refinement, the more time you can shave off her
              rotation. By far and ahead the biggest contribution to your team
              DPS by virtue of not having a character who does little damage on
              the field for too long.
            </div>
          )}
          {char != 'lumi' && char != 'taoqi' && (
            <div className="information with-padding">
              A utility-focused weapon prioritizing lightning-fast Concerto
              rotations over raw output with the ability to transform entire
              units' action priorities under the right circumstances. Not always
              a good choice but when implemented correctly makes all the
              difference. Also offers an immense amount of Energy Regen further
              accelerating rotations and supportive capabilities.
            </div>
          )}
        </>
      )}
      {name === 'Dauntless Evernight' && (
        <>
          {char === 'taoqi' && (
            <div className="information with-padding">
              The next best in line option for Taoqi - a completely free option
              acquired via exploration that boosts her DEF% and as such the bulk
              of her damage while providing no rotational advantage.
            </div>
          )}
          {char != 'taoqi' && (
            <div className="information with-padding">
              An easily accessible supportive option with a reasonable amount of
              Energy Regen. It also grants the unique ability to gain a sizable
              chunk of raw Resonance Energy once every 20 seconds. For most
              character's rotations in teams, this bonus will be ready every
              time it's their turn to switch-in, making this weapon a great
              choice for any character who doesn't need damage but needs their
              Ultimate fast and has trouble accessing it otherwise. The flat
              energy gain is more powerful the less energy the character and
              team they are a part of can generate.
            </div>
          )}
        </>
      )}
      {name === 'Broadblade of Voyager' && (
        <div className="information with-padding">
          An easily accessible supportive option with a reasonable amount of
          Energy Regen. It also grants the unique ability to gain a sizable
          chunk of raw Resonance Energy once every 20 seconds. For most
          character's rotations in teams, this bonus will be ready every time
          it's their turn to switch-in, making this weapon a great choice for
          any character who doesn't need damage but needs their Ultimate fast
          and has trouble accessing it otherwise. The flat energy gain is more
          powerful the less energy the character and team they are a part of can
          generate.
        </div>
      )}
      {/* Swords */}
      {name === "Bloodpact's Pledge" && (
        <div className="information with-padding">
          Aero Rover's Signature weapon, with a unique passive that allows them
          to Amplify the Aero DMG of all members in the team for 30s after using
          their Forte enhanced Skill, giving them great buffing capability for
          Aero units, and also granting lots of Energy Regen that allows getting
          your Ultimate back much easier.
        </div>
      )}
      {name === 'Unflickering Valor' && (
        <div className="information with-padding">
          Brant's signature and by far best weapon. The massive Energy Regen,
          Basic ATK DMG and small CRIT Rate boosts the weapon offers are all
          Brant's top stats, allowing him to sport the Tidebreaking Courage set
          comfortably, even with a Fusion DMG Bonus Echo if you build his Energy
          Regen well. However, this sword isn't good on anyone else — only pull
          it if you have Brant, and if you do pull it, know you'll only use it
          on him.
        </div>
      )}
      {name === 'Red Spring' && (
        <div className="information with-padding">
          Camellya's signature weapon is usable by all Basic Attack focused
          Sword wielding characters, but clearly designed with her in mind due
          to a lot of its power locked behind spending Concerto mid-rotation -
          something only Camellya can do. Offers a decent amount of ATK%, CRIT
          RATE% and Basic Attack DMG for all characters but a huge Basic Attack
          DMG bonus after spending Concerto.{' '}
          {char === 'brant' && (
            <>
              A reasonably good option for Brant given most of his damage is
              Basic ATK focused, but not quite as strong as it might seem at
              first glance due to it having no Energy Regen - one of Brant's
              best stats.
            </>
          )}
        </div>
      )}
      {name === 'Blazing Brilliance' && (
        <div className="information with-padding">
          Changli's signature weapon that's not only fantastic for her but also
          usable on all damage-focused Sword users who have a large portion of
          their damage originating from Resonance Skill damage. Offers a huge
          main stat of CRIT DMG% granting excellent scaling, 12% permanent ATK%
          and up to 56% increased Resonance Skill DMG% which should be at or
          close to full power during the bulk of your rotation when opening with
          one or two resonance skills.
        </div>
      )}
      {name === 'Emerald of Genesis' && (
        <>
          {char === 'brant' && (
            <div className="information with-padding">
              The combination of Energy Regen, CRIT Rate and solid Base ATK make
              this weapon a stand out choice for Brant, Its small ATK% boost it
              is also reasonably easy to work into his rotations. A top weapon
              option for Brant but one that doesn't allow him to use his best
              Echo set Tidebreaking Courage at S1 without other sources of
              Energy Regen.
            </div>
          )}
          {char != 'brant' && (
            <div className="information with-padding">
              Outstanding Sword choice, offering significantly higher base
              damage due to its 5★ rarity, as well as a stackable ATK% buff
              almost every character can effortlessly get 1 stack of (but with 2
              also being easily achievable by most when played correctly).
              Additionally has a good amount of Energy Regen, which on most
              characters will allow one sub-stat of Energy Regen to be allocated
              elsewhere to increase damage even further. Best Sword choice
              across the board with only S5 4★ Swords having a chance to compete
              with it.
            </div>
          )}
        </>
      )}
      {name === 'Lumingloss' && (
        <div className="information with-padding">
          Sword option for Heavy and Basic ATK users with an easy to trigger
          passive - with the only downside being its 10 second time limit. For
          characters that use their Skill multiple times per rotation, this
          downside is a non-issue but for Main DPS characters who take up a lot
          of field time and don't use their skill often this sword can lose
          value.{' '}
          {char === 'brant' && (
            <>
              Brant can easily keep this swords bonus active throughout his
              rotation, making it a very strong 4* choice, but its lack of
              Energy Regen can allow other options to pull ahead of it under
              specific circumstances.
            </>
          )}
        </div>
      )}
      {name === 'Lunar Cutter' && (
        <div className="information with-padding">
          Powerful sword for Resonators spending minimal time on the field - the
          shorter the better. Best for Hybrid characters executing their
          rotation fast and infrequently, but less powerful on Main DPS
          characters that aim to spend extended time on field.
        </div>
      )}
      {name === 'Commando of Conviction' && (
        <div className="information with-padding">
          Exceptional all-around generic Sword gaining its bonus close to
          unconditionally as most Resonators almost always aim to make use of
          their Intro skill and very few characters spend more than 15 seconds
          on field during their duration.
        </div>
      )}
      {name === 'Sword#18' && (
        <div className="information with-padding">
          Sword with decent base stats but a passive that only activates after
          taking sufficient damage after which it offers a sizable increase
          Heavy Attack DMG. Primarily suitable only for berserker characters
          that sacrifice their life to deal damage like Danjin, but can be used
          strategically after multiple copies have been merged (only calculated
          with bonus active on characters that consume their life to deal
          damage).
        </div>
      )}
      {name === 'Sword of Night' && (
        <div className="information with-padding">
          Easily accessible 3★ weapon that despite having lower base attack
          makes up for it with a high ATK% bonus at higher dupes. It does not
          have enough stats to compete with 4-star weapons but is a reasonable
          option if it's all you have. The only downside is that the buff only
          lasts 10 seconds, meaning it may fall off in the hands of a Main DPS
          character who takes up more field time.
        </div>
      )}
      {name === 'Overture' && (
        <>
          {char === 'brant' && (
            <div className="information with-padding">
              Overture is one of the only weapons Brant can use to reasonably
              satisfy Tidebreaking Courages requirements. With that said it's
              still not trivial, requiring many Energy Regen sub stats and
              double Energy Regen 3 cost echoes. If you can pull all of that off
              though, the solid damage boost from the Sword's huge Energy Regen
              stat combined with the boost from the Tidebreaker set over other
              options, makes this weapon a competitive choice. Unfortunately,
              the Concerto from this weapon serves no purpose as Brants
              rotations are based around his Forte gauge and not concerto or
              energy. Performs significantly worse without hitting the 250%
              energy threshold to use Tidebreaking Courage set.
            </div>
          )}
          {char === 'rover-aero' && (
            <div className="information with-padding">
              Best option for players who haven't yet played through the story
              enough to unlock Bloodpact's Pledge, which grants lots of Energy
              Regen to ensure the use of your Ultimate in every rotation and
              grants Concerto Energy via its passive effect, although this only
              has a circumstantial impact on Aero Rover, potentially allowing
              them to Outro after their first Forte skill instead of the second
              one if they get a Dodge and Dodge Counter off in their rotation
              beforehand, but beyond that it's not useful to them.
            </div>
          )}
          {char != 'brant' && char != 'rover-aero' && (
            <div className="information with-padding">
              A utility-focused weapon prioritizing lightning-fast Concerto
              rotations over raw output with the ability to transform entire
              units' action priorities under the right circumstances. Not always
              a good choice but when implemented correctly makes all the
              difference. Also offers an immense amount of Energy Regen further
              accelerating rotations and supportive capabilities.
            </div>
          )}
        </>
      )}
      {name === 'Sword of Voyager' && (
        <>
          {char === 'brant' && (
            <div className="information with-padding">
              Similar to Overture, this sword can be used to satisfy
              Tidebreaking Courages 250% energy requirement but is much much
              harder to achieve due to its lower energy stat. This sword is a
              worse version of Overture in every way and requires absurdly good
              Energy Regen gear to make viable but if you have nothing else and
              good gear you can make it can be a valid option. Performs
              significantly worse without hitting the 250% energy threshold to
              use Tidebreaking Courage set.
            </div>
          )}
          {char === 'rover-aero' && (
            <div className="information with-padding">
              F2P option for players who haven't yet played through the story
              enough to unlock Bloodpact's Pledge and don't have Overture to
              replace it, that gives Energy Regen and flat Resonance Energy to
              make using your Ultimate easier.
            </div>
          )}
          {char != 'brant' && char != 'rover-aero' && (
            <div className="information with-padding">
              An easily accessible supportive option with a reasonable amount of
              Energy Regen. It also grants the unique ability to gain a sizable
              chunk of raw Resonance Energy once every 20 seconds. For most
              character's rotations in teams, this bonus will be ready every
              time it's their turn to switch-in, making this weapon a great
              choice for any character who doesn't need damage but needs their
              Ultimate fast and has trouble accessing it otherwise. The flat
              energy gain is more powerful the less energy the character and
              team they are a part of can generate.
            </div>
          )}
        </>
      )}
      {name === 'Originite: Type II' && (
        <div className="information with-padding">
          Sword with the unique function of adding healing to a characters kit
          causing their Resonance Liberation to restore a small amount of health
          to themselves with a cooldown of 20 seconds. This sword has bad combat
          stats and the heal would otherwise be considered terrible for most
          content but it allows characters to activate the incredibly powerful
          5P Rejuvenating Glow set which requires healing to buff. This weapon
          should only be equipped when aiming to use the set on a character who
          could not otherwise heal.
        </div>
      )}
      {/* Gauntlets */}
      {name === 'Tragicomedy' && (
        <div className="information with-padding">
          Roccia's Signature and best in slot weapon. Has a tonne of CRIT RATE,
          making capping out her 70% requirement easy even with mediocre sub
          stats, while also having a solid amount of ATK% and a large amount of
          Heavy ATK DMG% which is her preferred Typed DMG boost.
        </div>
      )}
      {name === "Verity's Handle" && (
        <div className="information with-padding">
          Xiangli Yao's signature and an ideal weapon for any Resonance
          Liberation based Gauntlet user. Grants a tremendous 24.3% CRIT RATE
          boost and 12% generic DMG% boost all permanently and on top of that
          also grants an incredibly convenient 48% Resonance Liberation DMG%
          lasting 8 seconds on use of the wearers Liberation. Due to the timing
          of when this buff activates, it is almost always up when you need it
          most and the duration can also be extended up to 3 times for 5 seconds
          each time whenever you use a resonance skill. This extends the total
          duration of this buff to a whopping 23 seconds.
        </div>
      )}
      {name === 'Abyss Surges' && (
        <>
          {char === 'youhu' ? (
            <>
              <div className="information with-padding">
                Grants a small amount of Energy Generation but a much larger
                ATK% boost compared to other Weapons, offering a larger healing
                boost by comparison.
              </div>
            </>
          ) : (
            <>
              <div className="information with-padding">
                One of the top Gauntlets options in game with the only
                competitors being 4★ Weapons with multiple dupes. Offers high
                base ATK, ATK%, a good amount of Energy Regen to save at least 1
                sub-stat on gear to be reallocated to better uses and a
                conditional bonus boosting Resonance Skill damage and Basic
                Attacks that is very easy to satisfy.
              </div>
            </>
          )}
        </>
      )}
      {name === 'Hollow Mirage' && (
        <div className="information with-padding">
          Strong generic option for all Gauntlets users, offering an ATK% and
          DEF% after using Resonance Liberation in the form of 3 charges. Since
          most characters include their Liberation in their rotation in the
          endgame you can expect this bonus to be applied quickly and
          consistently. Unfortunately, whenever you are hit you'll lose 1 charge
          so to use this weapon effectively, you must minimize how many times
          you're hit. Calculations assume you can maintain your rotation without
          getting hit - if you think you will be lower your expectations of this
          weapon and it's position in the ranking.
        </div>
      )}
      {name === 'Gauntlets#21D' && (
        <>
          {char === 'youhu' ? (
            <>
              <div className="information with-padding">
                Offers a solid amount of Energy Generation as a main stat and a
                small boost to ATK% as a bonus ability in combat after dashing
                or dodging, improving Youhu's healing and Resonance Liberation
                access.
              </div>
            </>
          ) : (
            <>
              <div className="information with-padding">
                Gauntlets with a generic ATK% boost but an Energy Regen main
                stat, making them globally usable by all Gauntlets users but
                best for those who require the Energy Regen instead of more
                damage stats.
              </div>
            </>
          )}
        </>
      )}
      {name === 'Stonard' && (
        <div className="information with-padding">
          Specialist Gauntlets for Resonance Liberation scaling users, thanks to
          its passive that grants increased Resonance Liberation DMG% after
          using a Resonance Skill. Still usable by characters who don't focus
          specifically on Liberation damage as a big part of their kit, thanks
          to the reasonably high base stats but more importantly CRIT RATE main
          stat.
        </div>
      )}
      {name === 'Gauntlets of Night' && (
        <div className="information with-padding">
          Easily accessible 3★ weapon that despite having lower base attack
          makes up for it with a high ATK% bonus at higher dupes. It does not
          have enough stats to compete with 4-star weapons but is a reasonable
          option if it's all you have. The only downside is that the buff only
          lasts 10 seconds, meaning it may fall off in the hands of a Main DPS
          character who takes up more field time.
        </div>
      )}
      {name === 'Gauntlets of Voyager' && (
        <div className="information with-padding">
          An easily accessible supportive option with a reasonable amount of
          Energy Regen. It also grants the unique ability to gain a sizable
          chunk of raw Resonance Energy once every 20 seconds. For most
          character's rotations in teams, this bonus will be ready every time
          it's their turn to switch-in, making this weapon a great choice for
          any character who doesn't need damage but needs their Ultimate fast
          and has trouble accessing it otherwise. The flat energy gain is more
          powerful the less energy the character and team they are a part of can
          generate.
        </div>
      )}
      {name === 'Marcato' && (
        <>
          {char === 'youhu' ? (
            <>
              <div className="information with-padding">
                Best weapon for Youhu by far thanks to its massive Energy
                Generation % main stat and Concerto generation, allowing her to
                access her Outro far more easily. A top option at S1 or S5 but
                the more duplicates you have, the further this weapon pulls
                ahead of the competition.
              </div>
            </>
          ) : (
            <>
              <div className="information with-padding">
                A utility-focused weapon prioritizing lightning-fast Concerto
                rotations over raw output with the ability to transform entire
                units' action priorities under the right circumstances. Not
                always a good choice but when implemented correctly makes all
                the difference. Also offers an immense amount of Energy Regen
                further accelerating rotations and supportive capabilities.
              </div>
            </>
          )}
        </>
      )}
      {name === 'Originite: Type IV' && (
        <div className="information with-padding">
          Gauntlets with the unique function of adding healing to a characters
          kit causing their Basic Attacks to restore a small amount of health to
          themselves with a cooldown of 3 seconds. The Gauntlets have bad combat
          stats and the heal would otherwise be considered terrible for most
          content but it allows characters to activate the incredibly powerful
          5P Rejuvenating Glow set which requires healing to buff. This weapon
          should only be equipped when aiming to use the set on a character who
          could not otherwise heal.
        </div>
      )}
      {name === 'Amity Accord' && (
        <div className="information with-padding">
          Outstanding damage focused option for DEF-scaling damage dealers that
          deal a reasonable portion of their damage with their Resonance
          Liberation. Current best option for all DEF scalers.
        </div>
      )}
      {name === 'Guardian Gauntlets' && (
        <div className="information with-padding">
          Free to play accessible alternative for DEF-scaling damage dealers
          that make use of their Resonance Liberation as a damage dealing tool.
          A good alternative if you don't have Amity Accord.
        </div>
      )}
      {/* Rectivier */}
      {char === 'phoebe' && (
        <>
          {name === 'Luminous Hymn' && (
            <div className="information with-padding">
              Phoebe's signature weapon gives her a ton of CRIT Rate, ATK%,
              Heavy and Basic DMG bonus under the condition that the enemy is
              affected by Spectro Frazzle - it even amplifies Spectro Frazzle
              DMG after an Outro. All of these perks make this weapon the best
              for Phoebe.
            </div>
          )}
          {name === 'Rime-Draped Sprouts' && (
            <div className="information with-padding">
              Zhezhi's signature weapon is very usable on Phoebe thanks to the
              ton of CRIT DMG it provides, on top of the ATK% and conditional
              Basic ATK DMG buff Phoebe can exploit for a good portion of her
              rotation.
            </div>
          )}
          {name === 'Stringmaster' && (
            <div className="information with-padding">
              Yinlin's weapon sports amazing generalist stats - providing 36%
              CRIT Rate, an all-type DMG Bonus and a sizable ATK% buff which
              will be up for a reasonable duration - this makes it a close
              second for Phoebe.
            </div>
          )}
          {name === 'Augment' && (
            <div className="information with-padding">
              At R5, the combination of this weapon's incredibly high ATK% buff
              after using Ultimate alongside its sizeable CRIT Rate secondary
              stat, make this weapon the best 4-star option for Phoebe.
            </div>
          )}
          {name === "Ocean's Gift" && (
            <div className="information with-padding">
              This fully F2P friendly option is amazing for Phoebe - it gives
              her a ton of Spectro DMG and ATK which is all she wants to
              perform. CRIT will be harder to build effectively using this
              weapon, but the damage it gives can be worth it.
            </div>
          )}
          {name === 'Cosmic Ripples' && (
            <div className="information with-padding">
              This weapon provides a lot of ATK, Basic DMG Bonus and Energy
              Regen making it a decent option but not Phoebe's best. A lot of 5
              star and even 4 star weapons are more valuable for Phoebe due to
              having better and higher stat offerings that suit her better.
            </div>
          )}
          {name === 'Waltz in Masquerade' && (
            <div className="information with-padding">
              F2P craftable option that gives a lot of ATK% when fighting
              against enemies that have debuffs applied to them, but is
              outclassed by a lot of better options.
            </div>
          )}
        </>
      )}
      {char === 'cantarella' && (
        <>
          {name === 'Whispers of Sirens' && (
            <div className="information with-padding">
              Cantarella's signature weapon is expectedly perfect for her, as it
              gives her a huge boost to many stats including CRIT DMG, ATK,
              Basic ATK DMG Bonus and can even ignore Havoc RES. These buffs
              activate naturally quickly in her rotation, making the weapon
              especially powerful.
            </div>
          )}
          {name === 'Luminous Hymn' && (
            <div className="information with-padding">
              Phoebe's signature works as a pure stat stick for Cantarella — the
              buffs that it provides are linked to Spectro Frazzle so beyond an
              extra 12% ATK and 36% CRIT Rate this weapon's effects are
              completely unexploitable, however it gets enough stats to stand
              out as an option if you own it.
            </div>
          )}
          {name === 'Rime-Draped Sprouts' && (
            <div className="information with-padding">
              Cantarella can make great use of the passive effects of Zhezhi's
              signature weapon since she heavily benefits from Basic Attack DMG
              Bonus. It also has CRIT DMG and ATK% - 2 stats Cantarella enjoys
              as a damage dealer. Having 3 uses of her Resonance Skill,
              Cantarella is able to exploit this weapon's passive effects well
              too. This makes this option an outstanding choice for her if you
              own it.
            </div>
          )}
          {name === 'Stringmaster' && (
            <div className="information with-padding">
              Yinlin's signature weapon is a versatile option that gives a lot
              great stats such as CRIT Rate, All-Type DMG Bonus and ATK,
              especially if the character deals off-field damage or uses Swap
              Cancels. Because of this, it almost always lands as the #1 or #2
              weapon of choice for rectifier damage dealers. However, on
              Cantarella specifically, this weapon gives stats that can be
              diluted by some of her best teammates buffs, making Rime-Draped
              Sprouts equal or slightly better than it with other Supports
              buffing Cantarella.
            </div>
          )}
          {name === 'Augment' && (
            <div className="information with-padding">
              Outstanding generic Rectifier option, offering base CRIT RATE for
              excellent scaling but also an easily activatable and maintainable
              bonus ability granting ATK% after the wearer's Resonance
              Liberation is activated. Almost any character who can use CRIT
              RATE and ATK will be more than happy to have this weapon available
              only losing to 5★ options.
            </div>
          )}
          {name === 'Cosmic Ripples' && (
            <div className="information with-padding">
              The standard banner weapon does many good things for Cantarella.
              It offers a minor amount of Energy Regen that she very much
              appreciates, it gives a lot of ATK% for the extra damage and it
              has a stackable Basic ATK DMG Bonus effect that Cantarella heavily
              benefits from. Overall an amazing F2P-friendly option if you
              choose to acquire it.
            </div>
          )}
          {name === 'Fusion Accretion' && (
            <div className="information with-padding">
              Despite this weapon being in direct competition with other F2P 4*
              options, this weapon is very solid for any ATK% scaling
              characters. The ATK% boost it provides when casting your Resonance
              Skill is great on most characters. On Cantarella specifically, the
              extra Energy you get from its passive after casting your Resonance
              Skill is especially appreciated as Cantarella needs a lot of
              Energy funneled into her, making this fully F2P option perform way
              better than usual.
            </div>
          )}
          {name === 'Jinzhou Keeper' && (
            <div className="information with-padding">
              A good alternative 4★ Rectifier to Augment, offering an easy to
              maintain bonus ability granting ATK% after using an Intro skill
              and a good amount of ATK% as a main stat.
            </div>
          )}
        </>
      )}
      {char != 'phoebe' && char != 'cantarella' && (
        <>
          {name === 'Rime-Draped Sprouts' && (
            <div className="information with-padding">
              Zhezhi's Signature weapon that is tailored for her and characters
              that deal a large amount of their damage through Basic Attacks.
              Characters that intend to use this weapon should also be able to
              frequently launch Resonance Skills - ideally every 6 seconds while
              fighting. As a baseline the weapon offers a tremendous 72% CRIT
              DMG main stat alongside an unconditional 12% ATK buff, all on top
              of its Basic Attack DMG buffing capabilities. When using any
              Resonance Skill, the wearer gains a stack granting 12% Basic
              Attack DMG for 6 seconds stackable up to 3 times. This effect
              isn't easy to keep active for characters that aren't Zhezhi but if
              you can it's a nice bonus. Upon casting an Outro, if the wearer
              has a full 3 stacks of the buff all of them will be consumed
              replacing the buff with a 52% Basic Attack DMG bonus for 27
              seconds but only while that character remains off the field.
            </div>
          )}
          {name === 'Stellar Symphony' && (
            <div className="information with-padding">
              Shorekeeper’s signature weapon is not only amazing for her but
              also for any Rectifier support. Granting insane amounts of Energy
              Regen as its secondary stat, increasing the equipped character’s
              HP all the while providing additional Concerto Energy when casting
              your Ultimate is seriously valuable for any support that can equip
              the weapon.{' '}
              {char != 'verina' &&
                'It additionally provides a team-wide ATK buff if the equipped character’s Resonance Skill is used and it provides healing, which makes it stand out as an amazing support weapon.'}
            </div>
          )}
          {name === 'Stringmaster' && (
            <div className="information with-padding">
              Yinlin's signature Rectifier that stands far above all other
              options currently in the game for Rectifier damage dealers, thanks
              to its unethical amount of CRIT RATE and generic damage boost on
              top of a reasonably easy to active ATK% boost.
            </div>
          )}
          {name === 'Cosmic Ripples' && (
            <div className="information with-padding">
              An excellent Rectifier, only in competition with some 4★ Weapons
              with multiple dupes. Offers high base ATK, ATK%, a good amount of
              Energy Regen to save at least 1 sub-stat on gear to be reallocated
              to better uses and a stackable Basic ATK DMG% bonus that ramps up
              as you use more and more Basics.
            </div>
          )}
          {name === 'Augment' && (
            <div className="information with-padding">
              Outstanding generic Rectifier option, offering base CRIT RATE for
              excellent scaling but also an easily activatable and maintainable
              bonus ability granting ATK% after the wearer's Resonance
              Liberation is activated. Almost any character who can use CRIT
              RATE and ATK will be more than happy to have this weapon available
              only losing to 5★ options.
            </div>
          )}
          {name === 'Jinzhou Keeper' && (
            <div className="information with-padding">
              A good alternative 4★ Rectifier to Augment, offering an easy to
              maintain bonus ability granting ATK% after using an Intro skill
              and a good amount of ATK% as a main stat.
            </div>
          )}
          {name === 'Variation' && (
            <>
              {char === 'the-shorekeeper' && (
                <div className="information with-padding">
                  Best option for Shorekeeper outside of her signature - even if
                  you only have S1 as it still grants enough concerto to run an
                  optimal rotation without any additional actions. Also grants a
                  large amount of Energy Recharge as well making it an great
                  alternative option. Higher ranks of this weapon are beneficial
                  and allow for even faster rotations for Shorekeeper if certain
                  concerto breakpoints can be hit (e.g. relevant for those who
                  own Shorekeeper's Sequence 3).
                </div>
              )}
              {char === 'verina' && (
                <div className="information with-padding">
                  Without a doubt the best option on Verina, that enables her to
                  cut down one attack in her rotation and as such shave even
                  more time off her already super short rotation, effectively
                  boosting her team's damage even more. The Energy Regen is also
                  a really nice substat for Verina (and her best one at that.)
                </div>
              )}
              {char != 'the-shorekeeper' && char != 'verina' && (
                <div className="information with-padding">
                  A utility-focused weapon prioritizing lightning-fast Concerto
                  rotations over raw output with the ability to transform entire
                  units' action priorities under the right circumstances. Not
                  always a good choice but when implemented correctly makes all
                  the difference. Also offers an immense amount of Energy Regen
                  further accelerating rotations and supportive capabilities.
                </div>
              )}
            </>
          )}
          {name === 'Rectifier of Night' && (
            <div className="information with-padding">
              Easily accessible 3★ weapon that despite having lower base attack
              makes up for it with a high ATK% bonus at higher dupes. It does
              not have enough stats to compete with 4-star weapons but is a
              reasonable option if it's all you have. The only downside is that
              the buff only lasts 10 seconds, meaning it may fall off in the
              hands of a Main DPS character who takes up more field time.
            </div>
          )}
          {char === 'the-shorekeeper' ? (
            <>
              {name === 'Rectifier of Voyager' && (
                <div className="information with-padding">
                  A lower rarity weapon option every should have at maximum rank
                  compared to Rectifer #25 but with the downside of granting
                  less Energy Recharge on top of not having the concerto
                  generation of her Signature or Variation.
                </div>
              )}
            </>
          ) : (
            <>
              {name === 'Rectifier of Voyager' && (
                <div className="information with-padding">
                  An easily accessible supportive option with a reasonable
                  amount of Energy Regen. It also grants the unique ability to
                  gain a sizable chunk of raw Resonance Energy once every 20
                  seconds. For most character's rotations in teams, this bonus
                  will be ready every time it's their turn to switch-in, making
                  this weapon a great choice for any character who doesn't need
                  damage but needs their Ultimate fast and has trouble accessing
                  it otherwise. The flat energy gain is more powerful the less
                  energy the character and team they are a part of can generate.
                </div>
              )}
            </>
          )}
          {name === 'Rectifier#25' && (
            <>
              {char === 'the-shorekeeper' && (
                <div className="information with-padding">
                  An easier to acquire alternative to Variation or Stellar
                  Symphony that still offers Shorekeeper an excellent amount of
                  Energy Recharge for her Resonance Liberation. Does not have
                  any bonus concerto generation so additional actions will be
                  required on top of the her standard optimal rotation in order
                  to generate enough concerto for her Outro.
                </div>
              )}
              {char === 'verina' && (
                <div className="information with-padding">
                  An okay option for Verina by virtue of it providing Energy
                  Regen and ATK%, but not recommended over the 3 star option.
                  The only use case of this weapon is if you also have another
                  support who benefits more from this weapon than the 3 star
                  Rectifier of Voyager, in which case it holds value as it saves
                  resources.
                </div>
              )}
              {char != 'the-shorekeeper' && char != 'verina' && (
                <div className="information with-padding">
                  Decent F2P craftable option that provides a lot of Energy
                  Regen, some additional HP restore and some ATK% depending on
                  your current HP situation. Little value beyond the Energy
                  Regen and small ATK% boost.{' '}
                </div>
              )}
            </>
          )}
          {name === 'Celestial Spiral' && (
            <>
              {char === 'youhu' ? (
                <>
                  <div className="information with-padding">
                    Grants a decent amount of ATK% a flat Resonance Eergy bonus
                    every 20 seconds as opposed to a % multiplier which can be
                    an advantage in some teams but a disadvantage in others.
                  </div>
                </>
              ) : (
                <>
                  <div className="information with-padding">
                    Most often the best F2P 4* gauntlet option for DPS
                    characters scaling off of ATK. The ATK% boost it provides
                    when casting your Resonance Skill is great on most
                    characters, and while the small energy generation it
                    provides at the same time is more situational, it can be
                    handy as well.{' '}
                  </div>
                </>
              )}
            </>
          )}
          {name === 'Relativistic Jet' && (
            <div className="information with-padding">
              While in competition with other F2P 4* options, the buff uptime on
              this weapon is one of the most consistent and reliable for ATK
              scaling pistol DPS characters. The ATK% boost it provides when
              casting your Resonance Skill is great on most characters, and
              while the small energy generation it provides at the same time is
              more situational, it can be handy as well.{' '}
            </div>
          )}
          {name === 'Endless Collapse' && (
            <div className="information with-padding">
              Often inferior to Commando of Conviction for DPS, this sword is
              still a great F2P 4* option for characters who need the energy
              boost. Realistically however, only consider this option over
              Commando of Conviction if you have energy problems on your
              character.{' '}
            </div>
          )}
          {name === 'Fusion Accretion' && (
            <div className="information with-padding">
              Despite this weapon being in direct competition with other F2P 4*
              options, this weapon is very solid for any ATK% scaling
              characters. The ATK% boost it provides when casting your Resonance
              Skill is great on most characters, and while the small energy
              generation it provides at the same time is more situational, it
              can be handy as well.{' '}
            </div>
          )}
          {name === 'Waning Redshift' && (
            <div className="information with-padding">
              A solid F2P 4* broadblade option for any DPS character scaling off
              of ATK. The ATK% boost it provides when casting your Resonance
              Skill is great on most characters, and while the small energy
              generation it provides at the same time is more situational, it
              can be handy as well.{' '}
            </div>
          )}
          {name === 'Somnoire Anchor' && (
            <div className="information with-padding">
              An amazing, completely free 4* option for characters who have
              extended field times, providing a rare CRIT Rate boost and loads
              of ATK. However, due to the nature of its buffs stacking up over
              time, this sword will deminish a lot in power if the wielder swaps
              out in less than 10 seconds.{' '}
            </div>
          )}
        </>
      )}
    </>
  );
};
