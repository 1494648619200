/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { WWStatsJSON } from '../../data/ww-stats-data';
import { WWCharacterLevelsJSON } from '../../data/ww-char-levels';
import { StaticImage } from 'gatsby-plugin-image';

interface IProps {
  char_id: number;
  currentLevel?: number[];
  element: string;
  bonus: boolean;
}

export const WWStats: React.FC<IProps> = ({
  char_id,
  currentLevel,
  element,
  bonus
}) => {
  const [filteredList, setFilteredList] = useState(
    WWStatsJSON.filter((skill) => skill.unit_id === char_id)
  );
  const [listCharMultis, setListCharMultis] = useState(
    WWCharacterLevelsJSON.filter(
      (table) => table.level === Number(currentLevel)
    )
  );
  const [baseHP, setBaseHP] = useState(filteredList[0].base_hp);
  const [bonusHP, setBonusHP] = useState(1);
  const [baseDEF, setBaseDEF] = useState(filteredList[0].base_def);
  const [bonusDEF, setBonusDEF] = useState(1);
  const [baseATK, setBaseATK] = useState(filteredList[0].base_atk);
  const [bonusATK, setBonusATK] = useState(1);
  const [baseCRIT, setBaseCRIT] = useState(filteredList[0].base_crit);
  const [bonusCRIT, setBonusCRIT] = useState(0);
  const [baseCRITDMG, setBaseCRITDMG] = useState(filteredList[0].base_critdmg);
  const [bonusCRITDMG, setBonusCRITDMG] = useState(0);
  const [baseHealing, setBaseHealing] = useState(0);
  const [bonusHealing, setBonusHealing] = useState(0);
  const [baseDMG, setBaseDMG] = useState(0);
  const [maxEnergy, setMaxEnergy] = useState(filteredList[0].max_energy);

  useEffect(() => {
    setListCharMultis(
      WWCharacterLevelsJSON.filter(
        (table) => table.level === Number(currentLevel)
      )
    );
  }, [currentLevel]);

  useEffect(() => {
    if (
      char_id === 1403 ||
      char_id === 1203 ||
      char_id === 1104 ||
      char_id === 1204 ||
      char_id === 1501 ||
      char_id === 1502 ||
      char_id === 1602 ||
      char_id === 1402 ||
      char_id === 1102 ||
      char_id === 1202
    ) {
      if (bonus === true) {
        setBaseDMG(12);
        setBonusATK(filteredList[0].bonus_atk);
      } else {
        setBaseDMG(0);
        setBonusATK(1);
      }
    }

    if (
      char_id === 1302 ||
      char_id === 1405 ||
      char_id === 1404 ||
      char_id === 1106 ||
      char_id === 1504 ||
      char_id === 1206 ||
      char_id === 1607 ||
      char_id === 1205 ||
      char_id === 1107 ||
      char_id === 1304
    ) {
      if (bonus === true) {
        setBonusCRIT(8);
        setBonusATK(filteredList[0].bonus_atk);
      } else {
        setBonusCRIT(0);
        setBonusATK(1);
      }
    }

    if (
      char_id === 1301 ||
      char_id === 1603 ||
      char_id === 1606 ||
      char_id === 1305 ||
      char_id === 1506
    ) {
      if (bonus === true) {
        setBonusCRITDMG(16);
        setBonusATK(filteredList[0].bonus_atk);
      } else {
        setBonusCRITDMG(0);
        setBonusATK(1);
      }
    }

    if (char_id === 1601 || char_id === 1303) {
      if (bonus === true) {
        setBaseDMG(12);
        setBonusDEF(filteredList[0].bonus_def);
      } else {
        setBaseDMG(0);
        setBonusDEF(1);
      }
    }

    if (char_id === 1503 || char_id === 1406) {
      if (bonus === true) {
        setBonusHealing(12);
        setBonusATK(filteredList[0].bonus_atk);
      } else {
        setBonusHealing(0);
        setBonusATK(1);
      }
    }

    if (char_id === 1103 || char_id === 1505) {
      if (bonus === true) {
        setBonusHealing(12);
        setBonusHP(filteredList[0].bonus_hp);
      } else {
        setBonusHealing(0);
        setBonusHP(1);
      }
    }
  }, [bonus]);

  return (
    <>
      <div className="ww-stats">
        <div className="ww-box">
          <div className="info-list-row">
            <div className="category">
              <StaticImage
                src="../../../../images/ww/icons/stat_hp.png"
                alt="Stat"
              />
              HP
            </div>
            <div
              className={`details ${element} ${
                bonusHP === 1 ? '' : 'enhanced'
              }`}
            >
              {(
                baseHP *
                (listCharMultis[0].hp_multi / 10000) *
                bonusHP
              ).toFixed(0)}
            </div>
          </div>
        </div>
        <div className="ww-box">
          <div className="info-list-row">
            <div className="category">
              <StaticImage
                src="../../../../images/ww/icons/stat_atk.png"
                alt="Stat"
              />
              ATK
            </div>
            <div
              className={`details ${element} ${
                bonusATK === 1 ? '' : 'enhanced'
              }`}
            >
              {(
                baseATK *
                (listCharMultis[0].atk_multi / 10000) *
                bonusATK
              ).toFixed(0)}
            </div>
          </div>
        </div>
        <div className="ww-box">
          <div className="info-list-row">
            <div className="category">
              <StaticImage
                src="../../../../images/ww/icons/stat_def.png"
                alt="Stat"
              />
              DEF
            </div>
            <div
              className={`details ${element} ${
                bonusDEF === 1 ? '' : 'enhanced'
              }`}
            >
              {(
                baseDEF *
                (listCharMultis[0].def_multi / 10000) *
                bonusDEF
              ).toFixed(0)}
            </div>
          </div>
        </div>
        <div className="ww-box">
          <div className="info-list-row">
            <div className="category">
              <StaticImage
                src="../../../../images/ww/icons/stat_energy.png"
                alt="Stat"
              />
              Max Energy
            </div>
            <div className="details">{maxEnergy}</div>
          </div>
        </div>
        <div className="ww-box">
          <div className="info-list-row">
            <div className="category">
              <StaticImage
                src="../../../../images/ww/icons/stat_crit.png"
                alt="Stat"
              />
              CRIT Rate
            </div>
            <div
              className={`details ${element} ${
                bonusCRIT === 0 ? '' : 'enhanced'
              }`}
            >
              {baseCRIT + bonusCRIT}%
            </div>
          </div>
        </div>
        <div className="ww-box">
          <div className="info-list-row">
            <div className="category">
              <StaticImage
                src="../../../../images/ww/icons/stat_critdmg.png"
                alt="Stat"
              />
              CRIT DMG
            </div>
            <div
              className={`details ${element} ${
                bonusCRITDMG === 0 ? '' : 'enhanced'
              }`}
            >
              {baseCRITDMG + bonusCRITDMG}%
            </div>
          </div>
        </div>
        <div className="ww-box">
          <div className="info-list-row">
            <div className="category">
              <StaticImage
                src="../../../../images/ww/icons/stat_heal.png"
                alt="Stat"
              />
              Healing Bonus
            </div>
            <div
              className={`details ${element} ${
                bonusHealing === 0 ? '' : 'enhanced'
              }`}
            >
              {baseHealing + bonusHealing}%
            </div>
          </div>
        </div>
        <div className="ww-box">
          <div className="info-list-row">
            <div className={`category ${element}`}>
              {element === 'Aero' && (
                <>
                  <StaticImage
                    src="../../../../images/ww/icons/stat_wind.png"
                    alt="Stat"
                  />
                  Aero DMG
                </>
              )}
              {element === 'Electro' && (
                <>
                  <StaticImage
                    src="../../../../images/ww/icons/stat_ele.png"
                    alt="Stat"
                  />
                  Electro DMG
                </>
              )}
              {element === 'Fusion' && (
                <>
                  <StaticImage
                    src="../../../../images/ww/icons/stat_fire.png"
                    alt="Stat"
                  />
                  Fusion DMG
                </>
              )}
              {element === 'Glacio' && (
                <>
                  <StaticImage
                    src="../../../../images/ww/icons/stat_ice.png"
                    alt="Stat"
                  />
                  Glacio DMG
                </>
              )}
              {element === 'Havoc' && (
                <>
                  <StaticImage
                    src="../../../../images/ww/icons/stat_dark.png"
                    alt="Stat"
                  />
                  Havoc DMG
                </>
              )}
              {element === 'Spectro' && (
                <>
                  <StaticImage
                    src="../../../../images/ww/icons/stat_spec.png"
                    alt="Stat"
                  />
                  Spectro DMG
                </>
              )}
            </div>
            <div
              className={`details ${element} ${
                baseDMG === 0 ? '' : 'enhanced'
              }`}
            >
              {baseDMG}%
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
