/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WWCharacter } from '../common/components/ww-character';

interface IProps {
  name: string;
  element: string;
  role: string;
}

export const WWTeams: React.FC<IProps> = ({ name, element, role }) => {
  const verinaInfo =
    '- One of the usual Healing Support options that can slot into any team. Super easy to use, provides amazing team-wide ATK% buffs and 15% DMG Amplify with one of the fastest rotations in the game. Enjoy.																								';
  const shoreInfo =
    '- Similar to Verina, a premium Healing Support option who can not only slot into any team, but also provide insane 12.5% Crit Rate, 25% Crit DMG, 25% ATK and 15% DMG Amplify buffs to your whole team. One of the best but also easy to use characters in the game.																								';
  return (
    <>
      <p>
        Below you can find a list of some of the stronger synergies between this
        character and others.
      </p>
      {name === 'baizhi' && (
        <>
          <p>
            <strong className={`char-name ${element} `}>{name}</strong>{' '}
            <strong>is very flexible</strong> and can be be paired with most
            characters and fulfill the Support role in the team.{' '}
            {name === 'baizhi' &&
              "Worth mentioning she's incompatible with Coordinated Attack Hybrids though. Such as Yinlin, Zhezhi, Mortefi. Because she's only able to buff one character at once (due to the way her Outro and Inherent Skill 1 buffs work)."}
          </p>
        </>
      )}
      {name === 'verina' && (
        <>
          <p>
            <strong className={`char-name ${element} `}>{name}</strong> can be
            used literally anywhere and perform amazingly as she's the second
            best Support in the game, very slightly behind Shorekeeper. She's
            even the best in some Jinhsi teams that use secondaries that don't
            have Coordinated Attacks, and some quickswap teams that prefer her
            lower field time!{' '}
          </p>
        </>
      )}
      {name === 'the-shorekeeper' && (
        <>
          <p>
            <strong className={`char-name ${element} `}>{name}</strong> can be
            used with absolutely any character in the game. She is the best
            universal Support and will be the best option literally anywhere
            right now.
          </p>
        </>
      )}
      {name === 'aalto' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - Easily
              the best option to support Main DPS Aalto, sporting a 38% Basic
              Attack DMG Amplify on her Outro - his main damage type - which she
              accesses extremely quickly. Super easy and efficient - just slot
              her in on the Moonlit Clouds set, use her most important skills,
              and you're good to go.
            </li>
            <li>
              <WWCharacter mode="inline" slug="jiyan" enablePopover /> - Aalto's
              main (and really only) DPS option to support if you're playing him
              as a Hybrid. Amazing Aero DMG dealer who benefits a great amount
              from Aalto's Outro skill, which amplifies all Aero DMG by 23%.
              Note that Mortefi is a stronger option though.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'brant' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> - Brant
              is the most natural partner for Changli as he enables the queen of
              quickswap to be played in a more hypercarry playstyle. Changli
              doesn't do much damage in a hypercarry-style team composition
              without Brant, but his 20% Fusion 25% Skill DMG Amplify Outro
              makes the world of a difference for her. The two together make a
              team that can easily clear any kind of content.
            </li>
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - Brant
              is not only strong as a Hybrid character; he's also a very potent
              Main DPS. Running Sanhua for her 38% Basic ATK DMG Amplify to buff
              his personal damage isn't optimal, however it's still an amazing
              team. The playstyle with Brant and Sanhua is simple, has a ton of
              sustain and deals enough damage to work just fine. Do note that
              this duo isn't recommended for Whimpering Wastes though — it lacks
              maintainable AoE damage.
            </li>
            <li>
              <WWCharacter mode="inline" slug="chixia" enablePopover /> -
              Brant's Outro, very similarly to Changli, works completely fine in
              Dual DPS teams with Chixia. The two compensate for each others'
              downtimes and buffs the biggest damage type Chixia has (being
              Fusion Skill DMG), which makes this team very viable — arguably
              stronger than Changli.
            </li>
            <li>
              <WWCharacter mode="inline" slug="encore" enablePopover /> -
              Similarly to Changli, Brant is a good option to consider for Dual
              DPS teams with Encore. Unlike Changli's Liberation DMG Amplify
              Outro Buff, Encore can utilize Brant's Skill DMG Amplify Outro
              buff on top of his general Fusion DMG Amplify, which makes the
              pair even stronger than the Changli version, also thanks to
              Brant's amazing personal damage.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'calcharo' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="yinlin" enablePopover /> -
              Calcharo's most reliable Outro buffing option (25% Liberation, 20%
              Electro, his main damage types) who also does great damage
              off-field via Coordinated Attacks. Very compatible with swap
              cancels if you want to play Calcharo that way (loses DPS but gains
              consistency).
            </li>
            <li>
              <WWCharacter mode="inline" slug="jianxin" enablePopover /> -
              Another solid Outro buffing option for Calcharo (who amplifies
              Liberation DMG by 38%, Calcharo's main DMG type). Be careful
              however as Jianxin has extended field time and Energy generation
              problems, so you might need to build more Energy Regen on your
              Calcharo. The duo is also completely incompatible with Swap
              Cancelling.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'camellya' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="roccia" enablePopover /> - The
              premium Hybrid option for Camellya, who provides a flat 200 ATK
              via her Ultimate along with 20% Havoc DMG and 25% Basic Attack DMG
              Amplify on her Outro while retaining solid damage. Overall very
              comparable to Sanhua for extended field time reasons, so only pull
              her if you love her or love Camellya.
            </li>
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - A free
              top tier Hybrid that complements Camellya perfectly. Sporting a
              huge 38% Basic Attack DMG Amplify (Camellya's main damage type)
              and a lightning fast rotation time, she enables the crazy woman to
              steal the spotlight and deal huge damage. Very close in team
              damage to her premium option.
            </li>
            <li>
              <WWCharacter mode="inline" slug="danjin" enablePopover /> - If
              your Sanhua is taken up by another team and Roccia isn't on your
              account, use Danjin for a Hybrid. She can do good damage with only
              a slightly longer field time than Sanhua, and amplify all Havoc
              DMG by 23%, weaker than Sanhua's buffs but still amazing for
              Camellya. Otherwise, don't use Danjin.
            </li>
            <li>
              <WWCharacter mode="inline" slug="cantarella" enablePopover /> -
              Cantarella is an alternative option who fills a very similar role
              to Danjin — what she offers is less buff potential than her,
              however she sports significantly higher damage at minimal field
              time costs compared to Danjin; plus, she provides extra heals
              making the duo comfortable. However, we once again advise against
              using this pair unless your Sanhua is taken up, you want the extra
              heals, or you really like Cantarella.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'cantarella' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="camellya" enablePopover /> -
              While not Camellya's strongest pairing, thanks to Cantarella's 20%
              Havoc DMG Amplify on her Outro, her additional healing and good
              off-field damage, the two work naturally together. Cantarella with
              Camellya falls behind both Sanhua and Roccia in damage but boasts
              more comfortable sustain thanks to the extra heals, making it a
              viable option.
            </li>
            <li>
              <WWCharacter mode="inline" slug="rover-havoc" enablePopover /> -
              Similarly to Camellya, Havoc Rover will tend to prefer Danjin or
              Sanhua when focusing on damage (Camellya and Havoc Rover primarily
              doing Basic Attack DMG). With that being said, a chunk of Havoc
              Rover’s damage is also concentrated within both their Skill and
              Ultimate, so they appreciate the Skill amplification and the
              general Havoc amplification for their Ultimate as well. The extra
              healing Cantarella provides is also comfortable in any case. This
              makes the pairing very strong.
            </li>
            <li>
              <WWCharacter mode="inline" slug="danjin" enablePopover /> -
              Cantarella is the first character to properly enable Danjin to
              function as a potent Main DPS. She replaces the imperfect
              alternative of Mortefi, by allowing Danjin to exploit her multiple
              damage types thanks to her Skill and Havoc DMG Amplify Outro. The
              two together make for the best 4-star Main DPS team in the game
              (especially with S6 on Danjin), approaching the strength of some
              5-stars (while still performing slightly lower). However, be wary
              that Danjin's HP loss mechanic is especially accentuated when
              playing her as a Main DPS, so this team will take a lot of effort
              to learn.
            </li>
            <li>
              <WWCharacter mode="inline" slug="carlotta" enablePopover /> -
              Cantarella and Carlotta pair effectively thanks to Cantarella's
              ability to amplify Carlotta's overwhelming Skill DMG with her
              Outro. However, due to Cantarella's low energy generation,
              Carlotta will need to build more Energy Regen than usual for her
              Ultimate to accomodate for this. Outside of this restriction the
              combo is a good alternative to Zhezhi.
            </li>
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> - Like
              Carlotta, Cantarella can work well with Jinhsi thanks not only to
              her Skill DMG Amplification, but also her ability to execute
              Coordinated Attacks. Unfortunately, Energy Regen is once again an
              issue forcing Cantarella to need to run an Energy Regen main stat
              Echo in a lot of cases or unless she's built on Moonlit Clouds. On
              top of this, Jinhsi will also suffer not able to use her Ultimate
              more than once every 3 rotations. These factors hinder the pair
              compared to Zhezhi or even Yinlin, however it's still viable.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'carlotta' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="zhezhi" enablePopover /> - Easily
              the best SubDPS buffer for Carlotta, with amazing off-field
              damage, a fast rotation, a massive 20% Glacio and 25% Skill DMG
              Amplify on top of Energy generation on her Outro. A top tier duo
              that will make the game easy, no doubt.
            </li>
            <li>
              <WWCharacter mode="inline" slug="brant" enablePopover /> - Brant
              works just fine as an alternative option to buff Carlotta,
              sporting even higher damage than Zhezhi but buffing Carlotta less
              as she only benefits from 25% Skill DMG Amplify with Brant's
              Outro. That being said, Brant's high personal damage makes the duo
              competitive, arguably the best alternative to Zhezhi. His
              cooldowns also fit nicely with Carlotta's, making for a great
              pairing.
            </li>
            <li>
              <WWCharacter mode="inline" slug="cantarella" enablePopover /> -
              Similarly to Brant, Cantarella features a 25% Skill DMG Amplify
              Outro that can buff Carlotta nicely, on top of Moonlit Clouds when
              Brant typically runs a damage-focused Echo set instead of a
              buffing set. However, the team experiences massive Energy Regen
              issues — both your Carlotta and Cantarella will need to stack up
              lots of it, but if you can work around that issue, the team can be
              just as good as Brant.
            </li>
            <li>
              <WWCharacter mode="inline" slug="yangyang" enablePopover /> - For
              most players, Yangyang is the best F2P option for Carlotta. She
              doesn't have an Outro buff but more than makes up for it with her
              field time requirements, being one of the fastest in the game. You
              won't have Energy issues either, as Yangyang can funnel some into
              Carlotta via her Outro. However, this team experiences cooldown
              issues when optimized at full speed — at that point, we recommend
              Taoqi as the better F2P alternative.
            </li>
            <li>
              <WWCharacter mode="inline" slug="taoqi" enablePopover /> - Taoqi
              is a great F2P alternative for Carlotta, as her field time issues
              are mitigated by Carlotta having lengthy cooldowns and Taoqi's 38%
              Skill DMG Amplify Outro makes up for it. Yangyang will still
              outclass her for more casual players, as Yangyang funnels much
              needed Energy to Carlotta, however when optimized Carlotta may
              rotate too fast for her own good and spend several seconds doing
              nothing — in that case definitely run Taoqi, since her field time
              costs are mitigated and you get huge buffs as a trade-off, which
              is a huge boost to your overall damage.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'changli' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="brant" enablePopover /> -
              Changli's best partner for the overwhelming majority of players.
              The most of Changli's abilities are able to make great use of
              Brant's massive 20% Fusion and 25% Skill DMG Amplify Outro,
              causing a big boost to her damage, and Brant's personal damage is
              nothing to scoff at either. This makes the pair very natural
              together but also very strong; although be careful as Brant's
              Outro buff is lost when you switch Changli out.
            </li>
            <li>
              <WWCharacter mode="inline" slug="zhezhi" enablePopover /> -
              Zhezhi's 25% Skill DMG Amplify Outro buff can be utilized on
              Changli to replace Brant in a hypercarry-style team composition;
              however in spite of her extra flat Energy generation and despite
              the fact that she retains good personal damage off-field via
              Coordinated Attacks it's far from optimal — we only recommend her
              if you happen to own her, want to play Changli as a hypercarry,
              and don't own Brant.
            </li>
            <li>
              <WWCharacter mode="inline" slug="chixia" enablePopover /> -
              Leaning towards the easier-to-play side of Dual DPS teams, Chixia
              stands out as the only character currently being able to fully
              utilize Changli's Outro skill (20% Fusion and 25% Liberation DMG
              Amplify) and she's able to dish out some great damage because of
              it, on top of Changli's good personal damage, making the two a
              good pair.
            </li>
            <li>
              <WWCharacter mode="inline" slug="encore" enablePopover /> - A very
              good Main DPS option who can be supported by Changli via her Outro
              skill, which amplifies Fusion DMG by 20%. Combining their two good
              personal damage numbers together makes for a very solid team that
              can clear content easily when learnt. Be careful with this one as
              this is a step-up in difficulty compared to single DPS teams.
            </li>
            <li>
              <WWCharacter mode="inline" slug="xiangli-yao" enablePopover /> -
              The infamous hardcore Dual DPS pair for Changli, who can deal huge
              damage in long swap-cancellable windows that Changli uses to
              amazing effect. Additionally, Changli's 25% Liberation DMG Outro
              buff is appreciated to buff Xiangli Yao's key abilities like his
              ultimate nuke. When learnt, this duo deals monstrous damage. We
              however don't recommend it to any casual players, as its
              difficulty can just make it a bad team damage-wise if not properly
              played.
            </li>
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> - The
              teacher and student duo is famous not only for having some of the
              highest Expert quickswap DPS in the game, but also for being
              amazing in the new Whimpering Wastes mode — able to clear tons of
              waves very quickly when played right, this Expert quickswap duo
              won't disappoint if you play it right; however, that's a
              tremendous ask in terms of difficulty, so be careful, it might not
              net good results for casual players.
            </li>
            <li>
              <WWCharacter mode="inline" slug="yangyang" enablePopover /> - A
              particularly fun and fast duo with Changli, who's free to use,
              sports proper damage and is fully quickswap friendly. Still a
              difficult team to properly get the hang of, but not too much to
              still dish out good damage.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'chixia' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="brant" enablePopover /> - Brant's
              the perfect partner for Chixia, because he can contribute to a lot
              of the team's total damage while amplifying Chixia's Fusion and
              Skill DMG by 20 and 25% respectively. These two damage types are
              the ones Chixia utilizes the most — this makes the pair really
              strong and able to clear a ton of the endgame content with
              relative ease.
            </li>
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> - One
              of Chixia's highest damaging partners. Moderately difficult to
              play due to needing a Dual DPS playstyle, but allows Chixia to do
              great damage by having high personal damage herself and a nice 20%
              Fusion and 25% Liberation DMG Amplification on her Outro, big
              buffs that Chixia uses to great effect.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'danjin' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="camellya" enablePopover /> -
              Danjin's best Main DPS to support in her Hybrid playstyle. Huge
              Havoc DMG, the biggest AoE in the game and just a tiny bit of
              mental illness. Amazing pair despite Danjin falling behind
              slightly in Camellya teams compared to Roccia and Sanhua.
            </li>
            <li>
              <WWCharacter mode="inline" slug="rover-havoc" enablePopover /> -
              Easy to play in a hypercarry playstyle, but also usable in a Dual
              DPS semi-quickswap style, Havoc Rover and Danjin end up very
              flexible and strong together at all playing levels, all thanks to
              the general Havoc DMG buff Danjin provides on her Outro (Havoc
              Rover doing a lot of different DMG types).
            </li>
            <li>
              <WWCharacter mode="inline" slug="cantarella" enablePopover /> -
              Cantarella is the first character to properly enable Danjin to
              function as a potent Main DPS. She replaces the imperfect
              alternative of Mortefi, by allowing Danjin to exploit her multiple
              damage types thanks to her Skill and Havoc DMG Amplify Outro. The
              two together make for the best 4-star Main DPS team in the game
              (especially with S6 on Danjin), approaching the strength of some
              5-stars (while still performing slightly lower). However, be wary
              that Danjin's HP loss mechanic is especially accentuated when
              playing her as a Main DPS, so this team will take a lot of effort
              to learn.
            </li>
            <li>
              <WWCharacter mode="inline" slug="roccia" enablePopover /> - Roccia
              is a great option for Main DPS Danjin, sporting solid damage of
              her own, lots of grouping and a nice 20% Havoc and 25% Basic ATK
              DMG Amplify on her Outro on top of 200 Flat ATK on her Ultimate,
              buffs Danjin can make great use of.
            </li>
            <li>
              <WWCharacter mode="inline" slug="mortefi" enablePopover /> - The
              budget option if you want to play Main DPS Danjin and don't have
              premium 5-stars, amplifying Heavy Attacks (her main type as a Main
              DPS) by 38%. Providing additional buffs and personal damage with
              his Sequences, he dishes out some good off-field damage and buffs
              nicely, which is all Danjin needs. Surprisingly synergistic
              together thanks to Mortefi's S1 and Danjin's kit allowing him to
              make great use of his damage potential.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'encore' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - Easily
              Encore's best overall partner, by far too. Super short field time
              and amazing 38% Basic Attack DMG Amplify on Outro that complement
              each other's few swap cancel windows perfectly (while far from
              necessary).
            </li>
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> -
              Complicated synergy, but Encore benefits from one of her Outro
              buffs (20% Fusion DMG) and they're both able to deal good damage
              on their own. Harder to play than Sanhua and overall less
              rewarding, but good nonetheless.
            </li>
            <li>
              <WWCharacter mode="inline" slug="brant" enablePopover /> -
              Similarly to Changli, Brant is a good option to consider for Dual
              DPS teams with Encore. Unlike Changli's Liberation DMG Amplify
              Outro Buff, Encore can utilize Brant's Skill DMG Amplify Outro
              buff on top of his general Fusion DMG Amplify, which makes the
              pair even stronger than the Changli version, also thanks to
              Brant's amazing personal damage.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'jianxin' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="xiangli-yao" enablePopover /> -
              Jianxin's primary DPS to buff. Xiangli Yao enjoys Liberation
              damage buffers, making Jianxin a good alternative to Yinlin if you
              don't have her, despite Xiangli Yao being quite bothered by a
              character that takes a while to get their Outro off.
            </li>
            <li>
              <WWCharacter mode="inline" slug="calcharo" enablePopover /> -
              Exactly like Xiangli Yao, Calcharo enjoys Liberation damage
              buffers, making Jianxin a good alternative to Yinlin if you don't
              have her, despite him being quite bothered by a character that
              takes a while to get their Outro off.
            </li>
            <li>
              <WWCharacter mode="inline" slug="rover-havoc" enablePopover /> - A
              really intriguing and definitely non-optimal synergy, but one that
              makes sense. Havoc Rover's liberation is a lot of their damage,
              making Jianxin's Outro a good buff. Additionally, Havoc Rover
              having a smaller AoE than most Ultimates makes the grouping
              appreciated for AoE content. Pretty good pick for a support
              Jianxin, while not being the best team ever.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'jinhsi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="zhezhi" enablePopover /> - Easily
              Jinhsi's best partner. Easy to activate Coordinated Attacks with
              high uptime, 25% Skill DMG Amplify on her Outro, Energy
              generation, short field time, and good personal damage. Nothing
              else to be said, just one of the best teams in the game, plain and
              simple.
            </li>
            <li>
              <WWCharacter mode="inline" slug="yinlin" enablePopover /> -
              Actually pretty good even when compared to Zhezhi, due to having
              higher personal damage at the cost of slightly more field time.
              Still able to empower Jinhsi via lots of Coordinated Attacks and
              an albeit negligible 25% Liberation DMG Amplify on her Outro.
              Still a top tier pair though.
            </li>
            <li>
              <WWCharacter mode="inline" slug="yuanwu" enablePopover /> - Easily
              the best budget option for Jinhsi, able to be ran with the most
              minimal levels of investment and still being able to perform
              simply by virtue of generating the most stacks for Jinhsi at the
              cost of zero field time. Really easy team to play too. Strongly
              recommended for F2P casual players!
            </li>
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> - The
              teacher and student duo is famous not only for having some of the
              highest Expert quickswap DPS in the game, but also for being
              amazing in the new Whimpering Wastes mode — able to clear tons of
              waves very quickly when played right, this Expert quickswap duo
              won't disappoint if you play it right; however, that's a
              tremendous ask in terms of difficulty, so be careful, it might not
              net good results for casual players.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover /> - One of
              the usual Healing Support options that can slot into any team.
              Super easy to use, provides amazing team-wide ATK% buffs and 15%
              DMG Amplify with one of the fastest rotations in the game. Enjoy.
              Particularly good for Jinhsi due to providing Coordinated Attacks
              for faster Forte stack generation.
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              - Similar to Verina, a premium Healing Support option who can not
              only slot into any team, but also provide insane 12.5% Crit Rate,
              25% Crit DMG, 25% ATK and 15% DMG Amplify buffs to your whole
              team. One of the best but also easy to use characters in the game.
              Be wary however that she lacks Coordinated Attacks and won't
              generate Jinhsi's Forte stacks quickly, making her a bit harder to
              use!
            </li>
          </ul>
        </>
      )}
      {name === 'jiyan' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="mortefi" enablePopover /> - The
              best buffing option for Jiyan, who's likely to stick around for a
              while. Chances are you own him if you own Jiyan, since he's been
              featured on every banner Jiyan's been on, and there's no reason
              not to use him: insane 38% Heavy ATK DMG Amplify on Outro, more
              buffs with Sequences & Echoes, off-field damage via Coordinated
              Attacks. Perfect fit for Jiyan, plain and simple.
            </li>
            <li>
              <WWCharacter mode="inline" slug="aalto" enablePopover /> - The
              cope option if somehow you don't own Mortefi (or you really
              dislike Mortefi for some reason). It's less likely you own him
              than Mortefi, but if that's the case, his 23% Aero DMG Amplify on
              Outro is a nice buff, albeit a worse one than the scientist's.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'lingyang' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="zhezhi" enablePopover /> -
              Lingyang's best partner, by virtue of sporting good personal
              damage off-field via Coordinated Attacks and amazing 20% Glacio
              and 25% Skill DMG buffs on Outro that Lingyang very much
              appreciates.
            </li>
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - One of
              the fastest rotation times in the game with a very good 38% Basic
              ATK DMG Amplify buff on Outro allows her to buff Lingyang a great
              amount, and allows him to take the spotlight by doing most of the
              team's damage. Often used if Zhezhi is taken up by another team or
              if you don't own her since Sanhua's free.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'lumi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> - Lumi
              is able to significantly buff Jinhsi's nukes via her Outro which
              gives 38% Resonance Skill DMG Amplify. Synergy ends there, but
              it's simple and effective nuke damage potential. Be wary however
              that Shorekeeper will not work in that team, due to Jinhsi's Forte
              requiring the presence of Coordinated Attacks in her teams
              (something Lumi and Shorekeeper don't possess, but Verina does).
            </li>
            <li>
              <WWCharacter mode="inline" slug="carlotta" enablePopover /> -
              Carlotta does almost exclusively Skill DMG, which is exactly what
              Lumi amplifies by a whopping 38% on her Outro skill, making the
              synergy pretty natural. Be careful however that you have to time
              Lumi's Outro right as you activate your Forte nuke with Carlotta,
              else you won't get the buffs for your whole Ultimate. This means
              the team is rather awkward to play.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'mortefi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jiyan" enablePopover /> - Mortefi
              is by far Jiyan's best partner, as he does good off-field damage
              via Coordinated Attacks but most importantly amplifies Jiyan's
              Heavy ATK DMG by 38% on Outro, his main DMG type by far, making
              the synergy very strong and natural.
            </li>
            <li>
              <WWCharacter mode="inline" slug="danjin" enablePopover /> - If you
              want to use Danjin as a Main DPS, outside of Roccia, Mortefi is a
              pretty unconventional but effective option, as he amplifies
              Danjin's main DPS style DMG type (Heavy Attacks) by 38% via his
              Outro skill. The Coordinated Attack DMG is also very welcome,
              making the synergy noticeably decent.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'phoebe' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="rover-spectro" enablePopover /> -
              Spectro Rover is a necessary teammate for Phoebe: they provide the
              Spectro Frazzle Phoebe needs in order to deal a ton of Main DPS
              damage, on top of Spectro RES shred on Forte enhanced skill and
              healing on Ultimate. They can be run using two sets: Rejuvenating
              Glow and Moonlit Clouds, depending on your team, both almost
              equally as viable, so just run the third teammate you prefer and
              use one of the sets accordingly.
            </li>
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - The
              best teammate to use for casual Phoebe players running
              Rejuvenating Glow Spectro Rover. Sanhua amplifies Basic ATK DMG by
              38% on Outro, which she accesses insanely quickly, a welcome buff
              on Phoebe. However, being able to provide Moonlit Clouds buffs and
              a 20% ATK buff on S6 on top of a super fast rotation makes Sanhua,
              Phoebe and Spectro Rover the best casual Phoebe trio.
            </li>
            <li>
              <WWCharacter mode="inline" slug="mortefi" enablePopover /> -
              Contrary to what you might expect, Mortefi isn't the best option
              to amplify Phoebe's damage with a Rejuvenating Glow Spectro Rover.
              He does feature the same buffs as Sanhua on his S6, his Outro
              amplifies Phoebe's Heavy ATK DMG by 38%, an even more favorable
              buff than Sanhua's. On top of this, he can run Static Mist for an
              additional 10% ATK and can also run Moonlit Clouds. The answer
              lies in field time and Phoebe herself: Mortefi takes a while to
              access his Outro especially when compared to the lightning fast
              Sanhua, resulting in less time spent on your main damage dealer
              and less of your total time spent dealing a ton of damage. On top
              of this, Phoebe already amplifies her Heavy ATK DMG so much the
              buffs end up getting diluted due to diminishing returns; Mortefi
              as a result remains very close to Sanhua, but not better. We still
              recommend him if any of your other characters want Sanhua - she'll
              better be used on those characters than Phoebe.
            </li>
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> - The
              queen of quickswap returns yet again with one of the top meta DPS
              characters, to be used with a Rejuvenating Glow Spectro Rover. We
              really don't recommend you use Changli over Sanhua unless you're
              an expert on quickswaps - but if you have the ability to harness
              Changli's true potential, you'll love how much damage these two
              can do by working together.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover /> - One of
              the usual Healing Support options that can slot into any team. In
              Phoebe's case, use her on Rejuvenating Glow with a Moonlit Clouds
              Spectro Rover. Super easy to use, provides amazing team-wide ATK%
              buffs and 15% DMG Amplify with one of the fastest rotations in the
              game. Enjoy.
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              - Similar to Verina, a premium Healing Support option who can not
              only slot into any team, but also provide insane 12.5% Crit Rate,
              25% Crit DMG, 25% ATK and 15% DMG Amplify buffs to your whole
              team. In Phoebe's case, use her on Rejuvenating Glow with a
              Moonlit Clouds Spectro Rover. One of the best but also easy to use
              characters in the game.
            </li>
          </ul>
        </>
      )}
      {name === 'roccia' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="camellya" enablePopover /> -
              Roccia amplifies Havoc DMG by 20% and Basic Attack DMG by 25% on
              Outro, making the synergy with Camellya, who does those two damage
              types the most by far, extremely natural. Camellya's best in slot
              buffer, no doubt (despite being very competitive with Sanhua).
            </li>
            <li>
              <WWCharacter mode="inline" slug="rover-havoc" enablePopover /> -
              Roccia's 20% Havoc and 25% Basic Attack DMG Amplify on Outro are
              some of the best buffs Havoc Rover can receive, but additionally
              Roccia's grouping are very much appreciated with Havoc Rover's
              small AoE attacks that do big damage. Easily Havoc Rover's best
              buffer.
            </li>
            <li>
              <WWCharacter mode="inline" slug="danjin" enablePopover /> - Roccia
              is easily Main DPS Danjin's best buffing option if you want to
              play her in that style. The team is actually decent enough to
              clear endgame content.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'rover-aero' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jiyan" enablePopover /> - Aero
              Rover amplifies Jiyan's Aero DMG via their free signature weapon
              Bloodpact's Pledge passive, and the healing they provide means
              they can effortlessly trigger the Rejuvenating Glow set effect for
              some extra ATK% and Aero DMG% thanks to their weapon. This makes
              the pair suitable, but unfortunately they fall behind other
              Support options like Verina and Shorekeeper because of their
              higher buffing potential and Jiyan's inability to exploit Aero
              Erosion.
            </li>
          </ul>
        </>
      )}
      {name === 'rover-havoc' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="roccia" enablePopover /> - Havoc
              Rover's best buffer. Granting an amazing 20% Havoc and 25% Basic
              Attack DMG Amplify on Outro while retaining good personal damage
              and amazing grouping abilities that Havoc Rover very much benefits
              from when it comes to their Ultimate and Echo skills, the pair is
              extremely natural.
            </li>
            <li>
              <WWCharacter mode="inline" slug="danjin" enablePopover /> - Maybe
              surprising to some, but she's Havoc Rover's best buffer after
              Roccia, very close to her in strength. Use Danjin with short,
              low-damage rotations in favour of her 23% Havoc DMG Amplify on
              Outro that greatly benefits Havoc Rover due to having more than
              one damage type. This makes for an extremely strong F2P pair!
            </li>
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - If you
              don't want to invest into Danjin but you do have Sanhua, as she's
              top tier in other teams, she's Havoc Rover's 3rd best option.
              Following not too far behind Danjin, she has an extremely short
              rotation time and a 38% Basic ATK DMG Amplify on her Outro that
              Havoc Rover makes good use of.
            </li>
            <li>
              <WWCharacter mode="inline" slug="cantarella" enablePopover /> -
              Cantarella is a good alternative for Havoc Rover as she offers a
              20% Havoc DMG and 25% Skill DMG Amplify on her Outro that they
              appreciate. Cantarella also has good personal damage and extra
              heals for added comfort. However, this option is less recommended,
              as Danjin and Sanhua rotate faster than Cantarella and don't face
              any Energy Regen problems your team may have to build around, so
              only use Cantarella if you want the extra heals or like the
              character. She remains a competitive option though.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'rover-spectro' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="phoebe" enablePopover /> -
              Spectro Rover is a necessary teammate for Phoebe: they provide the
              Spectro Frazzle Phoebe needs in order to deal a ton of Main DPS
              damage, on top of Spectro RES shred on Forte enhanced skill and
              healing on Ultimate. They can be run using two sets: Rejuvenating
              Glow and Moonlit Clouds, depending on your team, both almost
              equally as viable, so just run the third teammate you prefer and
              use one of the sets accordingly.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover /> - One of
              the usual Healing Support options that can slot into any team. Use
              her on Rejuvenating Glow with a Moonlit Clouds Spectro Rover.
              Super easy to use, provides amazing team-wide ATK% buffs and 15%
              DMG Amplify with one of the fastest rotations in the game. Enjoy.
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              - Similar to Verina, a premium Healing Support option who can not
              only slot into any team, but also provide insane 12.5% Crit Rate,
              25% Crit DMG, 25% ATK and 15% DMG Amplify buffs to your whole
              team. Use her on Rejuvenating Glow with a Moonlit Clouds Spectro
              Rover. One of the best but also easy to use characters in the
              game.
            </li>
          </ul>
        </>
      )}
      {name === 'sanhua' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="camellya" enablePopover /> -
              Sanhua is a very very close second to Roccia in Camellya teams, so
              much so there's really no incentive to pull Roccia because she's
              too small of a DMG increase over the free glacio swordswoman.
              Super short field time with a massive 38% Basic ATK DMG Amplify on
              her Outro are all Sanhua needs to shine in Camellya teams.
            </li>
            <li>
              <WWCharacter mode="inline" slug="encore" enablePopover /> - Sanhua
              is by far Encore's best teammate period, over any quickswap
              options or limited 5 star options. Her buffs and lightning fast
              field time are so strong that Encore uses her better than she uses
              anyone else. So much so, the pair easily clears absolutely any
              content in the game. Amazing F2P duo.
            </li>
            <li>
              <WWCharacter mode="inline" slug="phoebe" enablePopover /> - Sanhua
              is the best teammate to use for casual Phoebe players running
              Rejuvenating Glow Spectro Rover. Sanhua amplifies Basic ATK DMG by
              38% on Outro, which she accesses insanely quickly, a welcome buff
              on Phoebe. However, being able to provide Moonlit Clouds buffs and
              a 20% ATK buff on S6 on top of a super fast rotation makes Sanhua,
              Phoebe and Spectro Rover the best casual Phoebe trio.
            </li>
            <li>
              <WWCharacter mode="inline" slug="brant" enablePopover /> - Sanhua
              is a very natural partner to a Hypercarry-style Brant as he deals
              Basic ATK DMG almost exclusively, allowing you to buff the
              overwhelming majority of his damage by a very good amount; seeing
              as Brant holds very high personal damage, the team is strong and
              will net great results, even if it's not as strong as Brant's Dual
              DPS teams.
            </li>
            <li>
              <WWCharacter mode="inline" slug="rover-havoc" enablePopover /> -
              In Havoc Rover teams, Sanhua is outclassed by both Roccia on the 5
              star side Danjin on the 4 star side, as Sanhua buffs Basic Attacks
              too heavily for someone with damage types that are spread thin
              like Havoc Rover. That said, Sanhua remains a good option if you
              don't want to invest in either, as she's top tier in 2 other meta
              teams.
            </li>
            <li>
              <WWCharacter mode="inline" slug="carlotta" enablePopover /> - In
              Carlotta teams, Sanhua is outclassed by both Zhezhi on the 5 star
              side and Yangyang on the 4 star side, because she barely buffs
              Carlotta at all and does negligible damage. However, if you don't
              own Zhezhi and don't want to invest into Yangyang as Sanhua is a
              top tier character in other meta teams, the two are very
              comparable in strength, so you can use Sanhua.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'taoqi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="carlotta" enablePopover /> - By
              far Taoqi's best DPS to support, as almost all of her damage is
              Resonance Skill DMG, which Taoqi amplifies by 38% on Outro, making
              the pair very natural. It's far from Carlotta's best team because
              Taoqi takes too long to get her buffs off, meaning it's only a
              good pair for nuke teams. But it's still viable enough to clear
              all content (as Carlotta is just that strong).
            </li>
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> - Taoqi
              can also support Jinhsi to deal great nuke DMG via Jinhsi's Skill
              decently you pair the two with Verina to make sure Forte stacks
              can be generated (Coordinated Attacks boost Forte stack generation
              immensely and are necessary for good Jinhsi nukes, something Taoqi
              doesn't have). It's far from meta but leads to good damage per
              screenshot.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'yangyang' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="carlotta" enablePopover /> -
              Yangyang is Carlotta's best option after Zhezhi if you aren't
              willing to quickswap a ton, thanks to her decent personal damage,
              her really short rotation time, her 20% ATK buff at S6 and her
              Outro which restores Energy to the incoming character, alleviating
              build requirements significantly.
            </li>
            <li>
              <WWCharacter mode="inline" slug="xiangli-yao" enablePopover /> -
              Similarly to Carlotta, Xiangli Yao appreciates all of Yangyang's
              buffs and her really short rotation time, however Xiangli Yao's
              many swap cancel windows not only make her strong in a quickswap
              playstyle, but straight-up the best non-limited 5 star option if
              you can manage playing two characters at once. Yangyang falls off
              quite a bit if you don't quickswap though, so this pair isn't
              recommended to casual players.
            </li>
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> -
              Changli's the quickswap queen, and Yangyang's kit being fully
              compatible with quickswapping makes the pair quite strong,
              surprisingly, by virtue alone of dealing the damage of two
              characters at once. It can clear content with relative ease under
              that condition, but Yangyang falls off quite a bit if you don't
              quickswap and isn't worth the use. Therefore, it's not recommended
              to casual players.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'yinlin' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> -
              Yinlin's Coordinated Attacks, personal damage, small buffs she
              provides to Jinhsi's Ultimate via Outro and general buffs with
              Echoes, and her swap cancel windows at a less casual level, make
              Jinhsi and Yinlin a competitive pair to Zhezhi (while being a bit
              worse), and one of the best teams in the game.
            </li>
            <li>
              <WWCharacter mode="inline" slug="xiangli-yao" enablePopover /> -
              Due to Yinlin's good personal off-field damage, but most
              importantly her extremely important 20% Electro and 25% Liberation
              DMG Amplify on Outro, she can buff Xiangli Yao by ludicrous
              amounts and make him a top DPS.
            </li>
            <li>
              <WWCharacter mode="inline" slug="calcharo" enablePopover /> -
              Similarly to Xiangli Yao, Yinlin's good personal off-field damage
              and her extremely important 20% Electro and 25% Liberation DMG
              Amplify on Outro buffs Calcharo a major amount, while also being
              able to trade those buffs off for consistency by abusing each
              other's many Swap Cancel windows, making the pair natural.
            </li>
            <li>
              <WWCharacter mode="inline" slug="youhu" enablePopover /> - Yinlin
              is actually the character Youhu buffs the most, due to Yinlin
              having the highest personal damage of any Coordinated Attack DPS
              in the game and her ability to amplify Coordinated Attack DMG by
              100% on Outro. As a result, the pairing is natural, while it
              doesn't compare to 5 star supports.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'youhu' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="yinlin" enablePopover /> -
              Youhu's meta niche is to buff Coordinated Attackers by amplifying
              Coordinated Attack DMG by 100% on Outro. As such, Yinlin, who has
              the highest DMG of any characters of that type, is the most
              natural pairing for her. While not comparable to 5 star supports,
              the pair is viable.
            </li>
            <li>
              <WWCharacter mode="inline" slug="zhezhi" enablePopover /> -
              Youhu's meta niche is to buff Coordinated Attackers by amplifying
              Coordinated Attack DMG by 100% on Outro. As such, Zhezhi is a
              natural pair due to her good Coordinated Attack DMG. While not
              comparable to 5 star supports, the pair is viable.
            </li>
            <li>
              <WWCharacter mode="inline" slug="mortefi" enablePopover /> -
              Youhu's meta niche is to buff Coordinated Attackers by amplifying
              Coordinated Attack DMG by 100% on Outro. As such, Mortefi is a
              natural pair due to his good Coordinated Attack DMG. While not
              comparable to 5 star supports, the pair is viable.
            </li>
          </ul>
        </>
      )}
      {name === 'yuanwu' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> -
              Yuanwu's only meta use case is to work as a good budget option for
              Jinhsi teams, as he's free to acquire, takes minimal field time,
              can activate the Rejuvenating Glow set easily with the Originite:
              Type IV 3 star weapon, and has the highest Coordinated Attack
              frequency of any character in the game. The pairing isn't meta,
              but requires minimal investment to perform, and remains a good
              option.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover /> - One of
              the usual Healing Support options that can slot into any team.
              Super easy to use, provides amazing team-wide ATK% buffs and 15%
              DMG Amplify with one of the fastest rotations in the game. Make
              sure to run her with Moonlit Clouds if you're using her along with
              Yuanwu, as she can trigger her Outro way more consistently &
              faster than he can every rotation!
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              - Similar to Verina, a premium Healing Support option who can not
              only slot into any team, but also provide insane 12.5% Crit Rate,
              25% Crit DMG, 25% ATK and 15% DMG Amplify buffs to your whole
              team. One of the best but also easy to use characters in the game.
              Make sure to run her with Moonlit Clouds if you're using her along
              with Yuanwu, as she can trigger her Outro way more consistently &
              faster than he can every rotation!
            </li>
          </ul>
        </>
      )}
      {name === 'xiangli-yao' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="yinlin" enablePopover /> - Easily
              Xiangli Yao's best easy to use partner, as she provides great
              off-field DMG via Coordinated Attacks, and amazing 20% Electro and
              25% Liberation DMG Amplify Outro buffs. The pair is natural.
            </li>
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> - Let's
              be clear: we don't recommend Changli+Xiangli Yao to casual
              players. The pair is extremely quickswap heavy and reliant on that
              mechanic to deal any semblance of good damage. It will
              underperform drastically if you don't quickswap a ton of attacks
              from both characters, especially compared to Yinlin. However, if
              you really want to push this game's limits, this duo is one of the
              highest damaging in the entire game. Good luck.
            </li>
            <li>
              <WWCharacter mode="inline" slug="jianxin" enablePopover /> - Good
              option for Xiangli Yao if you don't own Yinlin, as she amplifies
              Liberation DMG by 38% on Outro, which is a significant boost to
              his damage. However, Jianxin is awkward to use due to her extended
              field time and Energy problems. Only use her if you don't own
              Yinlin and aren't willing to quickswap.
            </li>
            <li>
              <WWCharacter mode="inline" slug="yangyang" enablePopover /> -
              Xiangli Yao's best option that isn't a limited 5 star if you are
              willing to quickswap. Xiangli Yao has several windows Yangyang can
              use to get a lot of attacks in and effectively allow you to play
              two characters at once, making for a great damaging pair. No
              Energy problems to worry about as well, thanks to Yangyang's
              Outro.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'zhezhi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="carlotta" enablePopover /> - DPS
              with simpler rotations doesn't get higher than this. Big off-field
              Coordinated Attack DMG, huge 20% Glacio and 25% Skill DMG Amplify
              on Outro along with Energy generation, fast rotations, all that to
              buff the best casual-friendly DPS in the game as high as currently
              possible. Nothing does better right now.
            </li>
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> -
              Approaching Carlotta very closely, the 25% Skill DMG Amplify on
              Outro, Energy generation, fast rotations, good personal DMG and
              Coordinated Attacks are enough to make Jinhsi and Zhezhi the
              second highest DPS pairing in the game for casual players.
              Trivializes content beyond belief.
            </li>
            <li>
              <WWCharacter mode="inline" slug="lingyang" enablePopover /> -
              Let's get this out of the way - Zhezhi is WAY better off buffing
              the 2 other DPS characters above, because they're the best
              currently and so they make the best use of her. However, Lingyang
              also uses Zhezhi's buffs perfectly and is an okay pair to use if
              you have neither (or simply like Lingyang). Note that Zhezhi
              benefits more from running Empyrean Anthem over Moonlit Clouds
              here as she contributes more of the team's total DMG.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      <div className={`content-header ${element}`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Example Teams
      </div>
      {(name === 'verina' ||
        name === 'the-shorekeeper' ||
        name === 'baizhi') && (
        <>
          <div className="info-box">
            <p>
              <strong className={`char-name ${element}`}>{name}</strong>{' '}
              <strong>is very flexible</strong> and can be used in any team in
              the Support slot.{' '}
            </p>
          </div>
        </>
      )}
      {name === 'aalto' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Team #1 - Main DPS</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter mode="icon" slug="aalto" enablePopover showLabel />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #2 - Hybrid</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter mode="icon" slug="jiyan" enablePopover showLabel />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="aalto" enablePopover showLabel />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {name === 'brant' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="brant"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Hypercarry Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="brant"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Double Fusion Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="chixia"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="brant"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'calcharo' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Best Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="calcharo"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yinlin"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter mode="icon" slug="youhu" enablePopover showLabel />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Budget Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="calcharo"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="jianxin"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>This team is entirely incompatible with Swap Cancels.</li>
            </ul>
          </div>
        </div>
      )}
      {name === 'camellya' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="camellya"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="roccia"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Alternative Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="camellya"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="cantarella"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'carlotta' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="carlotta"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="zhezhi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Alternative Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="carlotta"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="brant"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="cantarella"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="carlotta"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="yangyang"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="taoqi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'cantarella' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team 1 - Double Havoc</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="camellya"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="cantarella"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Use Cantarella on Midnight Veil here. It's recommended to use
                  Verina with Danjin because she takes a ton of field time as a
                  Main DPS.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Team 2 - Carlotta Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="carlotta"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="cantarella"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Your Carlotta may need more Energy Regen than usual for this
                  team to work.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Team 3 - Jinhsi Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="cantarella"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Strongly recommended to use Cantarella on Moonlit Clouds to
                  alleviate Energy requirements. Verina is a better pick than
                  Shorekeeper due to requiring less field time and providing
                  extra Coordinated Attacks.
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
      {name === 'changli' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="brant"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Zhezhi can work here as a replacement for Brant if you have
                  her — however note it'll perform quite a bit worse than Brant.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Expert Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="xiangli-yao"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  VERY difficult teams to play, won't net good results for most.
                  Pick your second teammate based on preference in
                  strengths/playstyle. Verina is always stronger with Jinhsi.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Dual Fusion Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="chixia"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Encore is stronger than Chixia, but more difficult to play.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Budget Dual DPS Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yangyang"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  This is a budget team so don't expect the best performance
                  here — however it'll still work well.
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
      {name === 'chixia' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="brant"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="chixia"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Changli Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="chixia"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'danjin' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team (Hybrid)</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="camellya"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Havoc Rover (Hybrid)</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Best Team (Main DPS)</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="cantarella"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="roccia"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  It is recommended to use Cantarella with Verina in this team —
                  Shorekeeper takes extended field time that may result in
                  cooldown issues.
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
      {name === 'encore' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team (Main DPS)</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Best Team (Dual DPS)</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'jianxin' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="xiangli-yao"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jianxin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Calcharo Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="calcharo"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jianxin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Support Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="roccia"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jianxin"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'jinhsi' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="zhezhi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  We recommend Verina over Shorekeeper for more casual players,
                  for ease of use reasons.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Yinlin Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  We recommend Verina over Shorekeeper for more casual players,
                  for ease of use reasons.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Expert Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  VERY difficult teams to play, won't net good results for most.
                  Verina is stronger than Shorekeeper as she provides
                  Coordinated Attacks.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Budget Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yuanwu"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  We recommend Verina over Shorekeeper for more casual players,
                  for ease of use reasons. To optimize, have Yuanwu on
                  Rejuvenating Glow with the Originite: Type IV weapon and your
                  healer on Moonlit Clouds.
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
      {name === 'jiyan' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jiyan"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Alt Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jiyan"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="aalto"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'lingyang' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="lingyang"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="zhezhi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="lingyang"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'lumi' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team (Jinhsi)</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="lumi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Best Team (Carlotta)</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="carlotta"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="lumi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>Rather awkward to play due to Lumi's short buff length.</li>
              </ul>
            </div>
          </div>
        </>
      )}
      {name === 'mortefi' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jiyan"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Danjin Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'phoebe' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="phoebe"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-spectro"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Run Mortefi if Sanhua is wanted by any other team you use.
                  Spectro Rover should be on Rejuvenating Glow.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Expert Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="phoebe"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-spectro"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Only use if you have a lot of quickswap experience, else run
                  the team above. Spectro Rover should be on Rejuvenating Glow.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Triple Spectro Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="phoebe"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-spectro"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>Spectro Rover should be on Moonlit Clouds.</li>
              </ul>
            </div>
          </div>
        </>
      )}
      {name === 'roccia' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="camellya"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="roccia"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Havoc Rover Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="roccia"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Danjin Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="roccia"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'rover-aero' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jiyan"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-aero"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'rover-havoc' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="roccia"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Danjin will perform slightly stronger than Sanhua, but feel
                  free to use Sanhua if you don't want to invest into Danjin.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Cantarella Alternative</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="cantarella"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Only use this team if you really want the extra heals — the
                  above teams will yield better results otherwise.
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
      {name === 'rover-spectro' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="phoebe"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-spectro"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Run Mortefi if Sanhua is wanted by any other team you use.
                  Spectro Rover should be on Rejuvenating Glow.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Expert Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="phoebe"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-spectro"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Only use if you have a lot of quickswap experience, else run
                  the team above. Spectro Rover should be on Rejuvenating Glow.
                </li>
              </ul>
            </div>
            <div className="team-header">
              <p>Triple Spectro Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="phoebe"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-spectro"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>Spectro Rover should be on Moonlit Clouds.</li>
              </ul>
            </div>
          </div>
        </>
      )}
      {name === 'sanhua' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Best Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="camellya"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Encore Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="encore"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Brant Hypercarry Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter mode="icon" slug="brant" enablePopover showLabel />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Budget Havoc Rover Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="rover-havoc"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Carlotta Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="carlotta"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {name === 'taoqi' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Best Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="carlotta"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="taoqi" enablePopover showLabel />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Jinhsi Nuke Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="jinhsi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="taoqi" enablePopover showLabel />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {name === 'yangyang' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Best Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="carlotta"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yangyang"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Budget Expert Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="xiangli-yao"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yangyang"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Not recommended to casual players as this falls off
                significantly without quickswapping.{' '}
              </li>
            </ul>
          </div>
          <div className="team-header">
            <p>Budget Expert Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="changli"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yangyang"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Not recommended to casual players as this falls off
                significantly without quickswapping.{' '}
              </li>
            </ul>
          </div>
        </div>
      )}
      {name === 'yinlin' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Xiangli Yao Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="xiangli-yao"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Calcharo Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="calcharo"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'youhu' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Best Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="calcharo"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yinlin"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="youhu" enablePopover showLabel />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Calcharo is presented here because Youhu's buffs matter more
                with Main DPS characters that do lower DMG, where Yinlin's own
                DMG matters more.
              </li>
            </ul>
          </div>
          <div className="team-header">
            <p>Zhezhi Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="lingyang"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="zhezhi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="youhu" enablePopover showLabel />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Lingyang is presented here because Youhu's buffs matter more
                with Main DPS characters that do lower DMG, where Zhezhi's own
                DMG matters more.
              </li>
            </ul>
          </div>
          <div className="team-header">
            <p>Mortefi Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter mode="icon" slug="jiyan" enablePopover showLabel />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="mortefi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="youhu" enablePopover showLabel />
              </div>
            </div>
          </div>
        </div>
      )}
      {name === 'yuanwu' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Best Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="jinhsi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yuanwu"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Make sure to run Yuanwu on Rejuvenating Glow and your third
                party member on Moonlit Clouds for optimal DPS!
              </li>
            </ul>
          </div>
        </div>
      )}
      {name === 'xiangli-yao' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Best Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="xiangli-yao"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yinlin"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter mode="icon" slug="youhu" enablePopover showLabel />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Youhu CAN be used here to buff Yinlin's Coordinated Attack DMG,
                but it's not optimal.
              </li>
            </ul>
          </div>
          <div className="team-header">
            <p>Expert Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="xiangli-yao"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="changli"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                VERY difficult to play. Not recommended to casual players.
              </li>
            </ul>
          </div>
          <div className="team-header">
            <p>Budget Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="xiangli-yao"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="jianxin"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Might face Energy problems and need more Energy Regen to be
                built on Xiangli Yao.
              </li>
            </ul>
          </div>
          <div className="team-header">
            <p>Budget Expert Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="xiangli-yao"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yangyang"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Not recommended to casual players, benefits strongly from Swap
                Cancels.
              </li>
            </ul>
          </div>
        </div>
      )}
      {name === 'zhezhi' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Best Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="carlotta"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="zhezhi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Jinhsi Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="jinhsi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="zhezhi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Lingyang Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="lingyang"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="zhezhi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
