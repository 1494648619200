/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { IWWSkill } from '../../common/model/graphql-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Link } from 'gatsby';
import { Range, getTrackBackground } from 'react-range';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    }
  }
};

interface IProps {
  skill: IWWSkill;
  element: string;
  char: string;
}

export const WWSkillRelease: React.FC<IProps> = ({ skill, element, char }) => {
  const [currentLevel, setCurrentLevel] = useState([1]);
  const [color, setColor] = useState('#979797');

  useEffect(() => {
    if (element === 'Spectro') {
      setColor('#f8e56c');
    } else if (element === 'Havoc') {
      setColor('#e649a6');
    } else if (element === 'Glacio') {
      setColor('#41aefb');
    } else if (element === 'Aero') {
      setColor('#55ffb5');
    } else if (element === 'Fusion') {
      setColor('#f0744e');
    } else if (element === 'Electro') {
      setColor('#b46bff');
    }
  }, []);
  return (
    <>
      <div className="box skill-new">
        <div className="skill-header">
          <div className={`skill-icon ${element}`}>{skill.type}</div>
          <div className="skill-info">
            <p className="skill-name">{skill.name}</p>
          </div>
        </div>
        <div className="skill-details">
          <div className={`skill-with-coloring ${element}`}>
            {renderRichText(skill.description, options)}
          </div>
          {skill.multipliers ? (
            <Accordion className="accordion-multis">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Multipliers</Accordion.Header>
                <Accordion.Body>
                  <div className="level-changer">
                    <span className="current-level">
                      Lv.{' '}
                      <span className="level" style={{ color: color }}>
                        {currentLevel}
                      </span>
                    </span>
                    <div
                      className={`level-slider ${
                        char === 'text' && 'disabled'
                      }`}
                    >
                      <Range
                        disabled={char === 'text' && true}
                        step={1}
                        min={1}
                        max={10}
                        values={currentLevel}
                        onChange={(values) => setCurrentLevel(values)}
                        renderTrack={({ props, children }) => (
                          <div
                            role="button"
                            tabIndex={0}
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: '36px',
                              display: 'flex',
                              width: '100%'
                            }}
                          >
                            <div
                              ref={props.ref}
                              style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '0px',
                                background: getTrackBackground({
                                  values: currentLevel,
                                  colors: [color, '#484950'],
                                  min: 1,
                                  max: 10
                                }),
                                alignSelf: 'center'
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: '20px',
                              width: '20px',
                              borderRadius: '0px',
                              backgroundColor: '#FFF',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              style={{
                                height: '14px',
                                width: '5px',
                                backgroundColor: isDragged ? color : '#484950'
                              }}
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="multipliers inside">
                    {skill.multipliers.map((multi, idx) => {
                      return (
                        <div key={idx} className="multi-single">
                          <div className="name">{multi.Name}</div>
                          <div className="value">
                            {Number(currentLevel) === 1 && multi.Lv1}
                            {Number(currentLevel) === 2 && multi.Lv2}
                            {Number(currentLevel) === 3 && multi.Lv3}
                            {Number(currentLevel) === 4 && multi.Lv4}
                            {Number(currentLevel) === 5 && multi.Lv5}
                            {Number(currentLevel) === 6 && multi.Lv6}
                            {Number(currentLevel) === 7 && multi.Lv7}
                            {Number(currentLevel) === 8 && multi.Lv8}
                            {Number(currentLevel) === 9 && multi.Lv9}
                            {Number(currentLevel) === 10 && multi.Lv10}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
