/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Chart from 'react-google-charts';

interface IProps {
  name: string;
  slug: string;
  element: string;
}

export const dataRover = [
  ['Skill', 'Dmg'],
  ['Basic', 7811],
  ['Heavy', 15731],
  ['Skill', 54268],
  ['Liberation', 60596],
  ['Intro', 9326],
  ['Outro', 0],
  ['Echo', 9346],
  ['Debuff', 13786]
];

export const dataRoverHavoc = [
  ['Skill', 'Dmg'],
  ['Basic', 137177],
  ['Heavy', 14974],
  ['Skill', 80530],
  ['Liberation', 113642],
  ['Intro', 9381],
  ['Outro', 31051],
  ['Echo', 47480]
];

export const dataJiyan = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 245392],
  ['Skill', 52448],
  ['Liberation', 0],
  ['Intro', 11247],
  ['Outro', 35458],
  ['Echo', 15478]
];

export const dataDanjin = [
  ['Skill', 'Dmg'],
  ['Basic', 8908],
  ['Heavy', 46529],
  ['Skill', 48561],
  ['Liberation', 56602],
  ['Intro', 10629],
  ['Outro', 0],
  ['Echo', 19276]
];

export const dataJianxin = [
  ['Skill', 'Dmg'],
  ['Basic', 48268],
  ['Heavy', 18878],
  ['Skill', 12367],
  ['Liberation', 56486],
  ['Intro', 7749],
  ['Outro', 0],
  ['Echo', 12546]
];

export const dataCalcharo = [
  ['Skill', 'Dmg'],
  ['Basic', 98697],
  ['Heavy', 0],
  ['Skill', 9050],
  ['Liberation', 213564],
  ['Intro', 20081],
  ['Outro', 29693],
  ['Echo', 20454]
];

export const dataEncore = [
  ['Skill', 'Dmg'],
  ['Basic', 143664],
  ['Heavy', 0],
  ['Skill', 45821],
  ['Liberation', 44546],
  ['Intro', 10587],
  ['Outro', 39258],
  ['Echo', 21566]
];

export const dataYinlin = [
  ['Skill', 'Dmg'],
  ['Basic', 11881],
  ['Heavy', 14196],
  ['Skill', 85714],
  ['Liberation', 32366],
  ['Intro', 5378],
  ['Outro', 0],
  ['Echo', 7290]
];

export const dataYangyang = [
  ['Skill', 'Dmg'],
  ['Basic', 40500],
  ['Heavy', 0],
  ['Skill', 17210],
  ['Liberation', 53643],
  ['Intro', 3897],
  ['Outro', 0],
  ['Echo', 12065]
];

export const dataMortefi = [
  ['Skill', 'Dmg'],
  ['Basic', 17046],
  ['Heavy', 0],
  ['Skill', 36848],
  ['Liberation', 139033],
  ['Intro', 6044],
  ['Outro', 0],
  ['Echo', 8399]
];

export const dataAalto = [
  ['Skill', 'Dmg'],
  ['Basic', 58083],
  ['Heavy', 0],
  ['Skill', 36636],
  ['Liberation', 17481],
  ['Intro', 8403],
  ['Outro', 0],
  ['Echo', 13118]
];

export const dataLingyang = [
  ['Skill', 'Dmg'],
  ['Basic', 134444],
  ['Heavy', 10213],
  ['Skill', 106796],
  ['Liberation', 23162],
  ['Intro', 11130],
  ['Outro', 66012],
  ['Echo', 21329]
];

export const dataSanhua = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 42821],
  ['Skill', 33188],
  ['Liberation', 34055],
  ['Intro', 5598],
  ['Outro', 0],
  ['Echo', 7915]
];

export const dataChixia = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 0],
  ['Skill', 147302],
  ['Liberation', 99972],
  ['Intro', 8613],
  ['Outro', 29494],
  ['Echo', 22538]
];

export const dataYuanwu = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 0],
  ['Skill', 57878],
  ['Liberation', 47263],
  ['Intro', 5858],
  ['Outro', 0],
  ['Echo', 8317]
];

export const dataJinhsi = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 0],
  ['Skill', 405629],
  ['Liberation', 93669],
  ['Intro', 23608],
  ['Outro', 0],
  ['Echo', 12034]
];

export const dataChangli = [
  ['Skill', 'Dmg'],
  ['Basic', 15851],
  ['Heavy', 7019],
  ['Skill', 221055],
  ['Liberation', 85792],
  ['Intro', 8103],
  ['Outro', 0],
  ['Echo', 22122]
];

export const dataZhezhi = [
  ['Skill', 'Dmg'],
  ['Basic', 159744],
  ['Heavy', 11274],
  ['Skill', 13367],
  ['Liberation', 0],
  ['Intro', 11078],
  ['Outro', 0],
  ['Echo', 8319]
];

export const dataXiangli = [
  ['Skill', 'Dmg'],
  ['Basic', 37807],
  ['Heavy', 0],
  ['Skill', 69284],
  ['Liberation', 229026],
  ['Intro', 10527],
  ['Outro', 40357],
  ['Echo', 20951]
];

export const dataShore = [
  ['Skill', 'Dmg'],
  ['Basic', 6052],
  ['Heavy', 0],
  ['Skill', 1882],
  ['Liberation', 57478],
  ['Intro', 0],
  ['Outro', 0],
  ['Echo', 4698]
];

export const dataCam = [
  ['Skill', 'Dmg'],
  ['Basic', 319984],
  ['Heavy', 0],
  ['Skill', 0],
  ['Liberation', 78645],
  ['Intro', 12217],
  ['Outro', 49675],
  ['Echo', 16260]
];

export const dataLumi = [
  ['Skill', 'Dmg'],
  ['Basic', 56023],
  ['Heavy', 0],
  ['Skill', 40993],
  ['Liberation', 46190],
  ['Intro', 5502],
  ['Outro', 0],
  ['Echo', 7089]
];

export const dataCarlotta = [
  ['Skill', 'Dmg'],
  ['Basic', 6056],
  ['Heavy', 0],
  ['Skill', 440311],
  ['Liberation', 0],
  ['Intro', 14569],
  ['Outro', 43872],
  ['Echo', 22373]
];

export const dataRoccia = [
  ['Skill', 'Dmg'],
  ['Basic', 8995],
  ['Heavy', 94617],
  ['Skill', 24041],
  ['Liberation', 0],
  ['Intro', 6951],
  ['Outro', 22811],
  ['Echo', 6951]
];

export const dataFibi = [
  ['Skill', 'Dmg'],
  ['Basic', 78692],
  ['Heavy', 248256],
  ['Skill', 7046],
  ['Liberation', 84862],
  ['Intro', 10694],
  ['Outro', 107048],
  ['Echo', 22187],
  ['Debuff', 29048]
];

export const dataBrant = [
  ['Skill', 'Dmg'],
  ['Basic', 169014],
  ['Heavy', 0],
  ['Skill', 0],
  ['Liberation', 44052],
  ['Intro', 10359],
  ['Outro', 0],
  ['Echo', 20588]
];

export const dataCanta = [
  ['Skill', 'Dmg'],
  ['Basic', 144413],
  ['Heavy', 4884],
  ['Skill', 14966],
  ['Liberation', 0],
  ['Intro', 6775],
  ['Outro', 20510],
  ['Echo', 17305]
];

export const dataRoverAero = [
  ['Skill', 'Dmg'],
  ['Basic', 5732],
  ['Heavy', 0],
  ['Skill', 77859],
  ['Liberation', 21860],
  ['Intro', 7666],
  ['Outro', 0],
  ['Echo', 2537]
];

export const options = {
  is3D: true,
  pieSliceText: 'percentage',
  backgroundColor: '#2C2D33',
  legend: {
    position: 'bottom',
    alignment: 'center',
    textStyle: { color: '#ffffff' },
    scrollArrows: { inactiveColor: '#ffffff', activeColor: '#ffffff' },
    pagingTextStyle: { color: '#ffffff' }
  },
  chartArea: { top: 20 },
  slices: {
    0: { color: '#009EEC' },
    1: { color: '#C267EC' },
    2: { color: '#f75058' },
    3: { color: '#56b972' },
    4: { color: '#76F8F8' },
    5: { color: '#F9A2A2' },
    6: { color: '#e29c55' },
    7: { color: '#FFFF7F' }
  }
};

export const WWDmgDistribution: React.FC<IProps> = ({
  name,
  slug,
  element
}) => {
  return (
    <>
      <div className="damage-distribution">
        <div className="top-part">
          <h5>
            <strong className={element}>{name}</strong> damage profile
          </h5>
        </div>
        {slug === 'rover-aero' && (
          <Chart
            chartType="PieChart"
            data={dataRoverAero}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'cantarella' && (
          <Chart
            chartType="PieChart"
            data={dataCanta}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'brant' && (
          <Chart
            chartType="PieChart"
            data={dataBrant}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'phoebe' && (
          <Chart
            chartType="PieChart"
            data={dataFibi}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'roccia' && (
          <Chart
            chartType="PieChart"
            data={dataRoccia}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'carlotta' && (
          <Chart
            chartType="PieChart"
            data={dataCarlotta}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'lumi' && (
          <Chart
            chartType="PieChart"
            data={dataLumi}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'camellya' && (
          <Chart
            chartType="PieChart"
            data={dataCam}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'the-shorekeeper' && (
          <Chart
            chartType="PieChart"
            data={dataShore}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'xiangli-yao' && (
          <Chart
            chartType="PieChart"
            data={dataXiangli}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'zhezhi' && (
          <Chart
            chartType="PieChart"
            data={dataZhezhi}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'changli' && (
          <Chart
            chartType="PieChart"
            data={dataChangli}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'jinhsi' && (
          <Chart
            chartType="PieChart"
            data={dataJinhsi}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'rover-spectro' && (
          <Chart
            chartType="PieChart"
            data={dataRover}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'yuanwu' && (
          <Chart
            chartType="PieChart"
            data={dataYuanwu}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'rover-havoc' && (
          <Chart
            chartType="PieChart"
            data={dataRoverHavoc}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'jiyan' && (
          <Chart
            chartType="PieChart"
            data={dataJiyan}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'danjin' && (
          <Chart
            chartType="PieChart"
            data={dataDanjin}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'jianxin' && (
          <Chart
            chartType="PieChart"
            data={dataJianxin}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'calcharo' && (
          <Chart
            chartType="PieChart"
            data={dataCalcharo}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'encore' && (
          <Chart
            chartType="PieChart"
            data={dataEncore}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'yinlin' && (
          <Chart
            chartType="PieChart"
            data={dataYinlin}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'yangyang' && (
          <Chart
            chartType="PieChart"
            data={dataYangyang}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'mortefi' && (
          <Chart
            chartType="PieChart"
            data={dataMortefi}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'aalto' && (
          <Chart
            chartType="PieChart"
            data={dataAalto}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'lingyang' && (
          <Chart
            chartType="PieChart"
            data={dataLingyang}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'sanhua' && (
          <Chart
            chartType="PieChart"
            data={dataSanhua}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'chixia' && (
          <Chart
            chartType="PieChart"
            data={dataChixia}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
      </div>
    </>
  );
};
